import React, { useState, useEffect } from "react";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import { FaRobot } from "react-icons/fa";
import AOS from "aos"; // Import AOS
import "aos/dist/aos.css"; // Import AOS styles
import config from "config.js";
import MessageParser from "chatbot/MessageParser.js";
import ActionProvider from "chatbot/ActionProvider.js";
import WhatsappChat from "whatsapp/WhatsappChat.js";
import Card from '@mui/material/Card';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navigation/Navbar";
import DefaultFooter from "examples/Footers/Footer/footer";

// Components
import Energy from "pages/LandingPages/Services/components/Energy"; // MaximizEE
import Monitoring from "pages/LandingPages/Services/components/dryair"; // CranE
import Iot from "pages/LandingPages/Services/components/Iot"; // Intellisenz
import GreenBos from "pages/LandingPages/Services/components/greenbos"; // GreenBOS

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import TopPic from "assets/images/partners/servicesBack2.jpg";

function Services() {
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);

  // Initialize AOS for animations
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const toggleChatbot = () => {
    setIsChatbotOpen((prevState) => !prevState);
  };

  return (
    <>
      <DefaultNavbar routes={routes} transparent light />

      {/* Hero Section */}
      <MKBox
        position="relative"
        width="100%"
        height={{ xs: "35vh", md: "55vh" }} // Adjusted height for mobile
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <img
          src={TopPic}
          alt="Top Image"
          width="1000"
          height="560"
          style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "right" }}
        />
        <MKTypography
          variant="h1"
          component="div"
          color="white"
          sx={{
            position: "absolute",
            fontSize: { xs: "1.75rem", sm: "2.5rem", md: "4rem" }, // Smaller font size for mobile
            fontWeight: "bold",
            textAlign: "center",
            textShadow: "2px 2px 4px rgba(0,0,0,0.6)",
            px: 2,
          }}
        >
          Energy Optimization Services
        </MKTypography>
      </MKBox>

      {/* Main Content */}
      <Card
        sx={{
          p: 0,
          mx: 0,
          mt: 0,
          mb: 0,
          borderRadius: 0,
        }}
      >
        {/* Top margin before components start */}
        <MKBox sx={{ mt: { xs: 3, md: 7 } }} />

        {/* Energy Section (MaximizEE) */}
        <MKBox
          data-aos="fade-left"
          sx={{
            width: "100%",
            height: { xs: "auto", md: "600px" },
            my: { xs: 2, md: 4 },
            p: { xs: 2, md: 4 },
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: { xs: "column", md: "row" }, // Stack vertically on mobile
          }}
        >
          <Energy />
        </MKBox>

        {/* Intellisenz Section */}
        <MKBox
          data-aos="fade-right"
          sx={{
            width: "100%",
            height: { xs: "auto", md: "600px" },
            my: { xs: 2, md: 4 },
            p: { xs: 2, md: 4 },
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: { xs: "column", md: "row" }, // Stack vertically on mobile
          }}
        >
          <Iot />
        </MKBox>

        {/* CranE Section */}
        <MKBox
          data-aos="fade-left"
          sx={{
            width: "100%",
            height: { xs: "auto", md: "600px" },
            my: { xs: 2, md: 4 },
            p: { xs: 2, md: 4 },
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: { xs: "column", md: "row" }, // Stack vertically on mobile
          }}
        >
          <Monitoring />
        </MKBox>

        {/* GreenBos Section */}
        <MKBox
          data-aos="fade-right"
          sx={{
            width: "100%",
            height: { xs: "auto", md: "600px" },
            my: { xs: 2, md: 4 },
            mb: {md: 10},
            p: { xs: 2, md: 4 },
            backgroundColor: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: { xs: "column", md: "row" }, // Stack vertically on mobile
          }}
        >
          <GreenBos />
        </MKBox>
      </Card>

      {/* Footer */}
      <MKBox pt={5} px={0} mt={-5}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>

      {/* Chatbot Button */}
      <MKBox
        sx={{
          position: "fixed",
          bottom: { xs: 15, md: 20 },
          left: { xs: 15, md: 20 },
          zIndex: 1000,
          cursor: "pointer",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "50px",
          height: "50px",
          backgroundColor: "#007BFF",
          borderRadius: "50%",
          boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
          transition: "transform 0.2s ease-in-out",
          '&:hover': {
            transform: "scale(1.1)",
          },
        }}
        onClick={toggleChatbot}
      >
        <FaRobot color="white" size="24px" />
      </MKBox>

      {isChatbotOpen && (
        <div
          id="chatbot-container"
          style={{
            position: "fixed",
            bottom: "80px",
            left: "20px",
            zIndex: 1000,
            backgroundColor: "white",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            borderRadius: "10px",
            padding: "10px",
            width: "calc(100% - 40px)",
            maxWidth: "400px",
            height: "400px",
            overflow: "hidden",
          }}
        >
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />
        </div>
      )}

      <WhatsappChat />
    </>
  );
}

export default Services;
