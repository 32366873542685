import React from "react";
import PropTypes from "prop-types";
import MKButton from "components/MKButton";
import "chatbot/CustomOption/Options.css";

const EmetryOptions = ({ actionProvider }) => {
  const options = [
    { text: "Analytics", handler: actionProvider.handleJsList, id: 1 },
    { text: "Monitoring", handler: actionProvider.handleMonitoring, id: 2 },
    { text: "IoT Architecture", handler: actionProvider.handleIoTArchitecture, id: 3 },
    { text: "About Us", handler: actionProvider.handleAboutUs, id: 4 },
    { text: "Contact Us", handler: actionProvider.handleContactUs, id: 5 },
  ];

  const optionsMarkup = options.map((option) => (
    <MKButton
      className="emetry-option-button"
      key={option.id}
      onClick={option.handler}
    >
      {option.text}
    </MKButton>
  ));

  return <div className="emetry-options-container">{optionsMarkup}</div>;
};

EmetryOptions.propTypes = {
  actionProvider: PropTypes.shape({
    handleJsList: PropTypes.func.isRequired,
    handleAnalytics: PropTypes.func.isRequired,
    handleMonitoring: PropTypes.func,
    handleIoTArchitecture: PropTypes.func.isRequired,
    handleSaving: PropTypes.func.isRequired,
    handleAboutUs: PropTypes.func.isRequired,
    handleContactUs: PropTypes.func.isRequired,
  }).isRequired,
};

export default EmetryOptions;

{ /* text: "Saving Analysis", handler: actionProvider.handleSaving, id: 4  */}
