import MKTypography from "components/MKTypography";
import logo from "assets/images/Pics _Chiller/logo.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "SquareCloud",
    image: logo,
    route: "/presentation" // Provide the appropriate route for the brand
  },
  menus: [
    {
      name: "About us",
      route: "/pages/landing-pages/about-us",
    },
    {
      name: "Our Solution",
      route: "/LandingPages/Services", 
    },
    {
      name: "Contact Us",
      route: "/pages/landing-pages/contact-us", 
    },
    {
      name: "Analytics",
      route: "/pages/LandingPages/Services/components/pages/analytics", 
    },
   
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular" color="white">
       ALL rights reserved. Copyright &copy; {date} SquareCloud Asia Sdn. Bhd
      <MKTypography
        component="a"
        href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
      </MKTypography>
      .
    </MKTypography>
  ),
};
