import React from "react";
import { Container, Grid } from '@mui/material';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from 'examples/Navigation/Navbar';
import DefaultFooter from 'examples/Footers/Footer/footer';
import routes from 'routes';
import footerRoutes from 'footer.routes';
import Card from "@mui/material/Card";
import Divider from '@mui/material/Divider';

// Import your images here
import Warranty from 'assets/images/warranty.png';
import Savings from 'assets/images/costSavings.jpg';
import Nationwide from 'assets/images/nationWide.jpg';
import TopPic from "assets/images/windmill.jpg";

function MonitoringImpact() {
  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox position="relative" width="100%" height="55vh" display="flex" alignItems="center" justifyContent="center">
        <img
          src={TopPic}
          alt="Top Image"
          style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "" }}
        />
        <MKTypography
          variant="h1"
          component="div"
          color="white"
          sx={{
            position: "absolute",
            fontSize: { xs: "3rem", sm: "4rem", md: "5rem" },
            fontWeight: "bold",
            mt: 8,
            textAlign: "center",
            textShadow: "2px 2px 4px rgba(0,0,0,0.6)",
          }}
        >
          Monitoring Services
        </MKTypography>
      </MKBox>
      <Card
        sx={{
          p: 0,
          mx: { xs: 0, lg: 0 },
          mt: 0,
          mb: 0,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          borderRadius:0,
        }}
      >
        <Container sx={{ py: 5, mt: 2, mb:1.5 }}>
          <Grid container spacing={3} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={5} textAlign="center">
              <MKBox component="img" src={Nationwide} alt="Nationwide Support" sx={{ width: '100%', height: '100%', mb: 2 }} />
              <MKTypography
                variant="h3"
                textAlign="center"
                mt={0}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  color: "#59535c",
                  [breakpoints.down("md")]: {
                    fontSize: size["2xl"],
                  },
                  [breakpoints.down("sm")]: {
                    fontSize: size["1xl"],
                  },
                })}
              >
                Nationwide Support
              </MKTypography>
              <Divider
                sx={{
                  margin: '10px auto',
                  width: '50%',
                  height: '3px',
                  backgroundColor: '#43abc1',
                }}
              />
              <MKTypography
                variant="body2"
                color="#59535c"
                mb={0}
                sx={({ breakpoints }) => ({
                  fontSize: "1.2rem",
                  lineHeight: "1.5",
                  textAlign: "center",
                  mt: 0,
                  mb: { xs:2, md: 0 },
                  [breakpoints.down("md")]: {
                    fontSize: "1rem",
                  },
                  [breakpoints.down("sm")]: {
                    fontSize: "0.875rem",
                  },
                })}
                >
                We maintain and update a comprehensive database of asset details and specifications using our CRANE system and Cloud Analytics Platform (CAP).
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={2} textAlign="center">
              </Grid>
            <Grid item xs={12} md={5} mt={-3.5} textAlign="center">
              <MKBox component="img" src={Savings} alt="Savings" sx={{ width: '100%', height: '100%', mb: 2 }} />
              <MKTypography
                variant="h3"
                textAlign="center"
                mt={0}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  color: "#59535c",
                  [breakpoints.down("md")]: {
                    fontSize: size["2xl"],
                  },
                  [breakpoints.down("sm")]: {
                    fontSize: size["1xl"],
                  },
                })}
                >
                Guaranteed Savings
              </MKTypography>
              <Divider
                sx={{
                  margin: '10px auto',
                  width: '50%',
                  height: '3px',
                  backgroundColor: '#43abc1',
                }}
              />
              <MKTypography
                variant="body2"
                color="#59535c"
                mb={0}
                sx={({ breakpoints }) => ({
                  fontSize: "1.2rem",
                  lineHeight: "1.5",
                  textAlign: "center",
                  mt: 0,
                  mb: { xs:2, md: 0 },
                  [breakpoints.down("md")]: {
                    fontSize: "1rem",
                  },
                  [breakpoints.down("sm")]: {
                    fontSize: "0.875rem",
                  },
                })}
                >
                This ensures real-time monitoring and control of asset information, enhancing operational efficiency.
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={5} textAlign="center">
              <MKBox component="img" src={Warranty} alt="Warranty" sx={{ width: '80%', height: 'auto', mb: 2 }} />
              <MKTypography
                variant="h3"
                textAlign="center"
                mt={0}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  color: "#59535c",
                  [breakpoints.down("md")]: {
                    fontSize: size["2xl"],
                  },
                  [breakpoints.down("sm")]: {
                    fontSize: size["1xl"],
                  },
                })}>
                Comprehensive Warranty
              </MKTypography>  
              <Divider
                sx={{
                  margin: '10px auto',
                  width: '50%',
                  height: '3px',
                  backgroundColor: '#43abc1',
                }}
              />
              <MKTypography
                variant="body2"
                color="#59535c"
                mb={0}
                sx={({ breakpoints }) => ({
                  fontSize: "1.2rem",
                  lineHeight: "1.5",
                  textAlign: "center",
                  mt: 0,
                  [breakpoints.down("md")]: {
                    fontSize: "1rem",
                  },
                  [breakpoints.down("sm")]: {
                    fontSize: "0.875rem",
                  },
                })}>
                Through advanced analytics and proactive maintenance strategies, we aim to minimize energy consumption while maximizing operational output.
              </MKTypography>
            </Grid>
            <Grid item xs={12} md={7} textAlign="center">
              </Grid>
          </Grid>
        </Container>
      </Card>
      <DefaultFooter content={footerRoutes} />
    </>
  );
}

export default MonitoringImpact;
