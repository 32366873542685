// @mui material components
// import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from '@mui/material/Divider';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// images
import Automated from "assets/images/partners/automated.jpg";

const imageStyles1 = {
  objectFit: "cover", // Maintain aspect ratio and cover the container
  width: "100%", // Full width of the container
  height: "100%", // Full height of the container to match the text
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)", // Zoom on hover
  },
};

function Information() {
  return (
<Grid
  container
  alignItems="center"
  rowSpacing={3}         
  columnSpacing={6}   
  sx={{ height: "auto" }}
  mt={{md: -4}}
  mb={{ xs: 1, md: -5 }}
>
  {/* Image Section */}
  <Grid
    item
    xs={12}
    md={5}
    order={{ xs: 1, md: 2 }} // Image first on mobile, second on desktop
    sx={{
      display: "flex",
      alignItems: "center",
      justifyContent: "center", // Center on mobile
      height: "100%",
    }}
  >
    <MKBox
      component="img"
      sx={{
        ...imageStyles1,
        height: "auto",
        maxHeight: "100%",
        maxWidth: "100%",
      }}
      src={Automated}
      alt="Automated"
    />
  </Grid>

  {/* Text Section */}
  <Grid
    item
    xs={12}
    md={7}
    order={{ xs: 2, md: 1 }} // Text second on mobile, first on desktop
    sx={{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      height: "100%",
    }}
  >
    <MKTypography
      variant="h3"
      
      mt={0}
      mb={1}
      sx={({ breakpoints, typography: { size } }) => ({
        textAlign: {
          xs: "center",
          md: "left",
          color: "#59535c",
        },
        [breakpoints.down("md")]: {
          fontSize: size["3xl"],
        },
        [breakpoints.down("sm")]: {
          fontSize: size["2xl"],
        },
      })}
    >
      ZERO CAPEX: Savings-as-a-Service for Chiller Plant Optimization
    </MKTypography>
    <Divider
      sx={{
        margin: { xs: "20px auto", md: "10px 0" },
        width: { xs: "50%", md: "30%" },
        height: { xs: "2px", md: "3px" },
        backgroundColor: "#43abc1",
      }}
    />
    <MKTypography
      variant="body2"
      color="#59535c"
      mb={0}
      maxWidth = {{md: 650}}
      sx={({ breakpoints }) => ({
        fontSize: "1.2rem",
        lineHeight: "1.75",
        textAlign: {
          xs: "center",
          md: "left",
        },
        mt: 4,
        [breakpoints.down("md")]: {
          fontSize: "1rem",
        },
        [breakpoints.down("sm")]: {
          fontSize: "0.875rem",
        },
      })}
    >
      Our ZERO CAPEX Savings-as-a-Service model revolutionizes the way building owners approach energy efficiency and cost savings. Through advanced engineering, retro-commissioning, and continuous optimization of chiller plants, we deliver substantial energy savings with no upfront capital expenditure.
      <br />
      <br />
      This innovative method allows building owners to enjoy immediate cost reductions and enhanced operational performance without the complexity or financial burden of traditional upgrades. Our solution ensures seamless integration into existing systems, making it easier than ever to unlock long-term value and sustainability.
    </MKTypography>
  </Grid>
</Grid>

  );
}


export default Information;
