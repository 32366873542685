import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, Grid } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import Lottie from "react-lottie";

// Import JSON animations from the assets folder
import EmpowermentAnimation from "assets/images/OurCoreValues-Icons/Empowerment.json";
import ExcellenceAnimation from "assets/images/OurCoreValues-Icons/Excellence.json";
import InnovationAnimation from "assets/images/OurCoreValues-Icons/Innovation.json";
import CollaborationAnimation from "assets/images/OurCoreValues-Icons/Collaboration.json";
import SustainabilityAnimation from "assets/images/OurCoreValues-Icons/Sustainability.json";

const values = [
  {
    name: "Empowerment",
    shortText: "Empowerment",
    fullText:
      "We empower businesses by providing intelligent, AI-driven tools to make informed decisions, optimize performance, and achieve their sustainability targets.",
    animation: EmpowermentAnimation, // Assign animation JSON
  },
  {
    name: "Excellence",
    shortText: "Excellence",
    fullText:
      "We commit to excellence in innovation, ensuring our solutions set new standards in sustainability and intelligent building operations.",
    animation: ExcellenceAnimation,
  },
  {
    name: "Innovation",
    shortText: "Innovation",
    fullText:
      "We relentlessly pursue cutting-edge technology, pushing the boundaries of IoT, AI, and data analytics to revolutionize building performance and sustainability.",
    animation: InnovationAnimation,
  },
  {
    name: "Collaboration",
    shortText: "Collaboration",
    fullText:
      "We collaborate with businesses and communities to empower them with the tools and insights needed for sustainable growth and energy efficiency.",
    animation: CollaborationAnimation,
  },
  {
    name: "Sustainability",
    shortText: "Sustainability",
    fullText:
      "We are dedicated to building a carbon-neutral future through intelligent energy management and sustainable practices that help businesses reduce their environmental impact.",
    animation: SustainabilityAnimation,
  },
];

function OurCoreValues() {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null); // Track hover index

  const handleClickOpen = (value) => {
    setSelectedValue(value);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedValue(null);
  };

  const squareSize = 300; // Size of each square
  const containerPadding = 55; // Padding for container

  const defaultOptions = (animationData) => ({
    loop: true,
    autoplay: false, // Disable autoplay to trigger on hover
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  });

  return (
    <>
      {/* Grid Layout for 5 Square Sections */}
      <MKBox
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          mt: 10,
          mb: 10,
          px: `${containerPadding}px`,
        }}
      >
        <Grid container spacing={5} justifyContent="center">
          {/* Map through values */}
          {values.map((value, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              onMouseEnter={() => setHoveredIndex(index)} // Set hover index
              onMouseLeave={() => setHoveredIndex(null)} // Reset hover index
            >
              <MKBox
                sx={{
                  width: `${squareSize}px`,
                  height: `${squareSize}px`,
                  backgroundColor: "#8cbcdb",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
                  cursor: "pointer",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
                onClick={() => handleClickOpen(value)}
              >
                {/* White Circle Container */}
                <MKBox
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                    width: "120px",
                    height: "120px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  {/* Lottie Animation */}
                  <Lottie
                    options={defaultOptions(value.animation)}
                    height={80}
                    width={80}
                    isStopped={hoveredIndex !== index} // Play animation on hover
                  />
                </MKBox>

                <MKTypography
                  variant="h5"
                  sx={{
                    color: "#fff",
                    fontWeight: "bold",
                    mb: 2,
                  }}
                >
                  {value.shortText}
                </MKTypography>
                <MKTypography
                  variant="body2"
                  sx={{
                    color: "#fff",
                    px: 2,
                    textAlign: "center",
                  }}
                >
                  {value.fullText.slice(0, 80)}... {/* Shortened description */}
                </MKTypography>
              </MKBox>
            </Grid>
          ))}
        </Grid>
      </MKBox>

      {/* Dialog Box for showing full text */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)",
            padding: 4,
          },
        }}
        maxWidth="sm"
      >
        <DialogTitle>
          <MKTypography variant="h5" fontWeight="bold">
            {selectedValue?.shortText}
          </MKTypography>
        </DialogTitle>
        <DialogContent>
          <MKTypography variant="body2" color="textSecondary">
            {selectedValue?.fullText}
          </MKTypography>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default OurCoreValues;
