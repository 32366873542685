import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // Import carousel styles
import { Carousel } from 'react-responsive-carousel';
import PropTypes from 'prop-types';

// Import images from the assets folder
import IMG_20240904_WA0009 from "assets/images/Newsletter/IMG-20240904-WA0009.jpg";
import IMG_20240904_WA0010 from "assets/images/Newsletter/IMG-20240904-WA0010.jpg";
import IMG_20240904_WA0011 from "assets/images/Newsletter/IMG-20240904-WA0011.jpg";
import IMG_20240904_WA0012 from "assets/images/Newsletter/IMG-20240904-WA0012.jpg";
import IMG_20240904_WA0013 from "assets/images/Newsletter/IMG-20240904-WA0013.jpg";
import IMG_20240904_WA0014 from "assets/images/Newsletter/IMG-20240904-WA0014.jpg";
import IMG_20240904_WA0015 from "assets/images/Newsletter/IMG-20240904-WA0015.jpg";
import IMG_20240904_WA0016 from "assets/images/Newsletter/IMG-20240904-WA0016.jpg";
import IMG_20240904_WA0017 from "assets/images/Newsletter/IMG-20240904-WA0017.jpg";
import IMG_20240904_WA0018 from "assets/images/Newsletter/IMG-20240904-WA0018.jpg";
import IMG_20240904_WA0019 from "assets/images/Newsletter/IMG-20240904-WA0019.jpg";
import IMG_20240904_WA0020 from "assets/images/Newsletter/IMG-20240904-WA0020.jpg";
import IMG_20240904_WA0021 from "assets/images/Newsletter/IMG-20240904-WA0021.jpg";
import IMG_20240904_WA0022 from "assets/images/Newsletter/IMG-20240904-WA0022.jpg";
import IMG_20240904_WA0023 from "assets/images/Newsletter/IMG-20240904-WA0023.jpg";
import IMG_20240904_WA0024 from "assets/images/Newsletter/IMG-20240904-WA0024.jpg";
import IMG_20240904_WA0025 from "assets/images/Newsletter/IMG-20240904-WA0025.jpg";
import IMG_20240904_WA0026 from "assets/images/Newsletter/IMG-20240904-WA0026.jpg";
import IMG_20240904_WA0027 from "assets/images/Newsletter/IMG-20240904-WA0027.jpg";
import IMG_20240904_WA0028 from "assets/images/Newsletter/IMG-20240904-WA0028.jpg";

// Import new images for the right slideshow
import news2 from "assets/images/Newsletter/news2.jpg";
import news2a from "assets/images/Newsletter/news2a.jpg";
import news2b from "assets/images/Newsletter/news2b.jpg";

const Slideshow = ({ images, selectedIndex, onSlideChange }) => {
  return (
    <div style={{ height: 'auto' }}>
      <Carousel
        selectedItem={selectedIndex}
        onChange={onSlideChange}
        showThumbs={false}
        showStatus={false}
        infiniteLoop
        autoPlay={false}
        interval={3000}
        stopOnHover
        dynamicHeight={false}
      >
        {images.map((image, index) => (
          <div
            key={index}
            style={{
              height: 'auto',
              overflow: 'hidden'
            }}
          >
            <img
              src={image}
              alt={`Slide ${index + 1}`}
              style={{
                maxWidth: '100%',
                height: 'auto',
                objectFit: 'contain',
              }}
            />
          </div>
        ))}
      </Carousel>
    </div>
  );
};

// Prop validation for Slideshow
Slideshow.propTypes = {
  images: PropTypes.arrayOf(PropTypes.string).isRequired,
  selectedIndex: PropTypes.number.isRequired,
  onSlideChange: PropTypes.func.isRequired,
};

function Newsletter() {
  // State to track the current slide index for each slideshow
  const [leftCurrentSlide, setLeftCurrentSlide] = useState(0);
  const [rightCurrentSlide, setRightCurrentSlide] = useState(0);

  // Images for the slideshows
  const leftSlideshowImages = [
    IMG_20240904_WA0009, IMG_20240904_WA0010, IMG_20240904_WA0011,
    IMG_20240904_WA0012, IMG_20240904_WA0013, IMG_20240904_WA0014,
    IMG_20240904_WA0015, IMG_20240904_WA0016, IMG_20240904_WA0017,
    IMG_20240904_WA0018, IMG_20240904_WA0019, IMG_20240904_WA0020,
    IMG_20240904_WA0021, IMG_20240904_WA0022,
    IMG_20240904_WA0023, IMG_20240904_WA0024, IMG_20240904_WA0025,
    IMG_20240904_WA0026, IMG_20240904_WA0027, IMG_20240904_WA0028
  ];

  const rightSlideshowImages = [news2, news2a, news2b];

  // Auto-slide for left slideshow
  useEffect(() => {
    const intervalId = setInterval(() => {
      setLeftCurrentSlide((prev) => (prev + 1) % leftSlideshowImages.length);
    }, 10000); // Change slides every 10 seconds

    return () => clearInterval(intervalId); // Cleanup the interval
  }, [leftSlideshowImages.length]);

  // Auto-slide for right slideshow
  useEffect(() => {
    const intervalId = setInterval(() => {
      setRightCurrentSlide((prev) => (prev + 1) % rightSlideshowImages.length);
    }, 10000); // Change slides every 10 seconds

    return () => clearInterval(intervalId); // Cleanup the interval
  }, [rightSlideshowImages.length]);

  return (
    <Grid
      container
      sx={{
        backgroundColor: "#d9e8ee",
        padding: { xs: "20px 10px", md: "65px 125px" }, // Adjusted padding for mobile
        maxWidth: { md: "1275px" }, // Limits the width on larger screens
        margin: "0 auto", // Centers the component horizontally
        paddingLeft: { md: "20px" }, // Adds left padding for mobile and desktop
        paddingRight: { md: "20px" }, // Adds right padding for mobile and desktop
        alignContent: { md: "center" }, // Centers content vertically within the grid
      }}
      mt={-1.7}
      mb={-3}
    >
      <Grid
        item
        xs={12}
        md={6}
        sx={{
          paddingRight: { md: "52.9px" }, // Half of 105.8px for desktop, removed for mobile
          boxSizing: 'border-box',
          mb: { xs: 4, md: 0 }, // Added margin-bottom for mobile to create space between items
        }}
      >
        <MKBox
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflow: 'hidden',
          }}
        >
          <Slideshow
            images={leftSlideshowImages}
            selectedIndex={leftCurrentSlide}
            onSlideChange={(index) => setLeftCurrentSlide(index)}
          />
          <MKBox mt={2}>
            <MKTypography
              variant="h4"
              textAlign="center"
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
                [breakpoints.down("sm")]: {
                  fontSize: size["xl"],
                },
              })}
            >
              Tour by CEO of Newcastle University
            </MKTypography>
            <MKTypography
              variant="body1"
              textAlign="center"
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: {
                  fontSize: size["lg"],
                },
                [breakpoints.down("sm")]: {
                  fontSize: size["md"],
                },
              })}
            >
              Visitors from Newcastle University Medicine Malaysia: Mr Dennis Wong, CEO, and Mr. Jeffrey Cheng, Senior Facilities Manager, arrived at Mimos for a tour on Mimos.
            </MKTypography>
          </MKBox>
        </MKBox>
      </Grid>

      <Grid
        item
        xs={12}
        md={6}
        sx={{
          paddingLeft: { md: "52.9px" }, // Half of 105.8px for desktop, removed for mobile
          boxSizing: 'border-box',
        }}
      >
        <MKBox
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            overflow: 'hidden',
          }}
        >
          <Slideshow
            images={rightSlideshowImages}
            selectedIndex={rightCurrentSlide}
            onSlideChange={(index) => setRightCurrentSlide(index)}
          />
          <MKBox mt={2}>
            <MKTypography
              variant="h4"
              textAlign="center"
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: {
                  fontSize: size["2xl"],
                },
                [breakpoints.down("sm")]: {
                  fontSize: size["xl"],
                },
              })}
            >
              MIMOS Unveils New Smart Energy Technologies
            </MKTypography>
            <MKTypography
              variant="body1"
              textAlign="center"
              sx={({ breakpoints, typography: { size } }) => ({
                color: "#59535c",
                [breakpoints.down("md")]: {
                  fontSize: size["lg"],
                },
                [breakpoints.down("sm")]: {
                  fontSize: size["md"],
                },
              })}
            >
            MIMOS introduced the Mi-ACE system, an energy-efficient chiller control technology. Mi-ACE aims to enhance energy savings in buildings through AI and IoT. 
            </MKTypography>
          </MKBox>
        </MKBox>
      </Grid>
    </Grid>
  );
}

export default Newsletter;
