import React from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Information() {
  return (
    <>
      {/* Main Content Section */}
      <MKBox component="section" py={4} sx={{ mt: "0.5cm", mx: "0.3cm" }}>
        <Container>
          <Grid container spacing={2} alignItems="center">
            <Grid item xs={12}>
              <MKBox mb={3}>
                <MKTypography
                  variant="h3"
                  textAlign="center"
                  mt={2}
                  mb={2}
                  sx={({ breakpoints, typography: { size } }) => ({
                    color: "#59535c",
                    fontFamily: "'Caudex', serif",
                    fontWeight: "bold",
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: size["2xl"],
                    },
                  })}
                >
                  Our Journey: The Dream Behind Squarecloud
                </MKTypography>
                <MKTypography
                  variant="body2"
                  color="#59535c"
                  sx={({ breakpoints }) => ({
                    fontSize: "1.2rem",
                    lineHeight: "1.75",
                    textAlign: "left",
                    mt: 0,
                    [breakpoints.down("md")]: {
                      fontSize: "1rem",
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: "1rem",
                      textAlign: "justify",
                    },
                  })}
                >
                  Founded in 2016, Squarecloud was born from a vision to empower building managers and owners through digital transformation. Our mission is to unlock the full potential of building and service performance by integrating cutting-edge technology and data-driven solutions.
                  <br /><br />
                  The name Squarecloud symbolizes our core values: Square represents the buildings we optimize, while Cloud signifies the digital transformation and analytics we harness to scale efficiency.
                  <br /><br />
                  As we grew, Squarecloud evolved into a consultancy, guiding clients on optimizing plant, building, and equipment efficiencies. Our focus became identifying inefficiencies, particularly in energy consumption, and helping businesses reduce waste and improve performance.
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      {/* Section 1: Innovating for a Sustainable Future */}
      <MKBox component="section" py={4} sx={{ mx: "0.3cm" }}>
        <Container>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12}>
              <MKBox mb={3}>
                <MKTypography
                  variant="h3"
                  textAlign="left"
                  mt={0}
                  mb={2}
                  sx={({ breakpoints, typography: { size } }) => ({
                    color: "#504f59",
                    fontFamily: "'Caudex', serif",
                    fontWeight: "bold",
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: size["2xl"],
                    },
                  })}
                >
                  Innovating for a Sustainable Future
                </MKTypography>
                <MKTypography
                  variant="body2"
                  color="#333"
                  sx={({ breakpoints }) => ({
                    fontSize: "1.1rem",
                    lineHeight: "1.75",
                    textAlign: "left",
                    mt: 0,
                    [breakpoints.down("md")]: {
                      fontSize: "1rem",
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: "1rem",
                      textAlign: "justify",
                    },
                  })}
                >
                  By 2019, Squarecloud became a System Integrator, leveraging IoT and software to monitor and address inefficiencies in real-time. We developed our proprietary IoT platform, allowing for standardized, efficient, and scalable implementations.
                  <br /><br />
                  Since 2020, we’ve expanded into IoT monitoring and control systems across multiple industries, focusing on Smart Chiller systems.
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>

      {/* Section 2: Our Commitment to Sustainability */}
      <MKBox component="section" py={4} sx={{ mx: "0.3cm" }}>
        <Container>
          <Grid container spacing={2} alignItems="flex-start">
            <Grid item xs={12}>
              <MKBox mb={4}>
                <MKTypography
                  variant="h3"
                  textAlign="left"
                  mt={0}
                  mb={2}
                  sx={({ breakpoints, typography: { size } }) => ({
                    color: "#504f59",
                    fontFamily: "'Caudex', serif",
                    fontWeight: "bold",
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: size["2xl"],
                    },
                  })}
                >
                  Our Commitment to Sustainability
                </MKTypography>
                <MKTypography
                  variant="body2"
                  color="#333"
                  sx={({ breakpoints }) => ({
                    fontSize: "1.1rem",
                    lineHeight: "1.75",
                    textAlign: "left",
                    mt: 0,
                    [breakpoints.down("md")]: {
                      fontSize: "1rem",
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: "1rem",
                      textAlign: "justify",
                    },
                  })}
                >
                  At SquareCloud, we are dedicated to creating a carbon-neutral future through innovation and collaboration.
                  By harnessing advanced IoT and AI technologies, we help businesses optimize energy use and reduce environmental impact.
                  <br /><br />
                  Together, we set new standards for intelligent building operations, driving sustainability and operational excellence.
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
        </Container>
      </MKBox>
    </>
  );
}

export default Information;
