import React from "react";
import PropTypes from "prop-types";
import "chatbot/List/LinkList.css";

const LinkList = ({ options }) => {
  const linkMarkup = options.map((link) => (
    <li key={link.id} className="link-list-item">
      <a
        href={link.href}
        target="_blank"
        rel="noopener noreferrer"
        className="link-list-item-url"
      >
        {link.text}
      </a>
    </li>
  ));

  return <ul className="link-list">{linkMarkup}</ul>;
};

LinkList.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      href: PropTypes.string.isRequired,
      id: PropTypes.number.isRequired,
    })
  ).isRequired,
};

export default LinkList;
