import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import MKButton from 'components/MKButton';
import { saveDataToExcel, fetchCombinedDataFromFirebase, db } from 'firebase.js';
import SavingData from 'layouts/pages/landing-pages/get-data/saving-data';
import calculateChillerData from 'layouts/pages/landing-pages/get-data/calculate-data';
import { getStorage, ref, uploadBytes } from 'firebase/storage';
import { collection, doc, writeBatch } from 'firebase/firestore';

const GetData = () => {
  const storage = getStorage();
  const [tableData, setTableData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const collections = [
    'SavingsForm',
    'ChillerForm',
    'AddInfo',
  ];

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const data = await fetchCombinedDataFromFirebase(collections);
      const formattedData = collections.reduce((acc, collectionName) => {
        const collectionData = data.filter(item => item.collectionName === collectionName);
        if (collectionName === 'ChillerForm') {
          acc[collectionName] = collectionData.flatMap(doc => doc.chillers || []);
        } else {
          acc[collectionName] = collectionData;
        }
        return acc;
      }, {});
      setTableData(formattedData);
      console.log('Fetched table data:', formattedData);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportToExcel = async (event) => {
    event.preventDefault();

    try {
      if (Object.keys(tableData).length > 0) {
        const excelBlob = saveDataToExcel(tableData);
        const excelFileRef = ref(storage, 'files/data_info.xlsx');
        await uploadBytes(excelFileRef, excelBlob);
        alert('Data saved successfully!');
      } else {
        console.error('No data available to export.');
      }
    } catch (error) {
      console.error('Error exporting data to Excel:', error);
    }
  };

    const handleCalculate = async (setIsLoading) => {
    setIsLoading(true);
    try {
      const { formattedData } = await calculateChillerData(setIsLoading);
      console.log("Data calculated", formattedData);
  
      const batch = writeBatch(db);
      const chillerForm = formattedData.ChillerForm || [];
      chillerForm.forEach(item => {
        const docRef = doc(collection(db, 'ChillerForm'), item.id);
        batch.set(docRef, item);
      });
      await batch.commit();
      console.log("Calculated data saved to Firestore");
      alert('Calculated data saved successfully!');
    } catch (error) {
      console.error("Error calculating or saving data:", error);
      alert('Error calculating or saving data.');
    } finally {
      setIsLoading(false);
    }
  };
  
  const renderTables = () => {
    if (isLoading) return <p>Loading data....</p>;

    return Object.entries(tableData).map(([collection, data], index) => (
      <div key={index}>
        <h3>{collection}</h3>
        {Array.isArray(data) && data.length > 0 ? (
          data.map((subData, subIndex) => (
            <div key={subIndex}>
              <SavingData formData={subData} columns={Object.keys(subData)} /> {/* Pass dynamic columns */}
            </div>
          ))
        ) : (
          data !== null ? (
            <SavingData formData={data} columns={Object.keys(data)} />
          ) : (
            <p>No data available for {collection}</p>
          )
        )}
      </div>
    ));
  };

  return (
    <div>
      <Stack spacing={2} direction="row">
        <MKButton variant="outlined" color="dark" onClick={fetchData}>
          Display
        </MKButton>
        <MKButton variant="contained" onClick={handleExportToExcel}>
          Export to Excel
        </MKButton>
        <MKButton variant="contained" color="dark" onClick={handleCalculate}>
          Calculate Data
        </MKButton>
      </Stack>
      {renderTables()}
    </div>
  );
};

export default GetData;
