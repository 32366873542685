import { fetchCombinedDataFromFirebase } from 'firebase.js';

// Initialize tariff before calculating present kWh per month
const calculateChillerData = async (setIsLoading) => {
  setIsLoading(true);
  try {
    const collections = ['SavingsForm', 'ChillerForm', 'AddInfo'];
    const data = await fetchCombinedDataFromFirebase(collections);
    const savingsForm = data.find(item => item.collectionName === 'SavingsForm');

    const formattedData = collections.reduce((acc, collectionName) => {
      const collectionData = data.filter(item => item.collectionName === collectionName);
      if (collectionName === 'ChillerForm') {
        const chillersWithCalculations = collectionData.map(doc => ({
          ...doc,
          chillers: calculateTotalSaved12(calculateTotalSaved10(calculateTotalSaved8(calculateSaving12Percent(calculateSaving10Percent(calculateSaving8Percent(calculateTwelve(calculateTenPercent(calculateEightPercent(calculateMonth(calculatePU(calculateRating(calculateEfficiency(calculateChillerAge(doc.chillers || [])
          )
          )
          ), savingsForm.tariffOptions, savingsForm.tariffOption
          ),
          ),
          ),
          ),
          ),
          ),
          ),
          ),
          ),
          ),
        }));
        acc[collectionName] = chillersWithCalculations;
      } else {
        acc[collectionName] = collectionData;
      }
      return acc;
    }, {});

    return { formattedData };
  } catch (error) {
    console.error('Error fetching data:', error);
    return {}; // Return empty object on error
  } finally {
    setIsLoading(false);
  }
};

// Calculate chiller age
const calculateChillerAge = (chillers) => {
  return chillers.map(chiller => ({
    ...chiller,
    chillerAge: new Date().getFullYear() - chiller.chillerYear,
  }));
};

// Efficiency
const calculateEfficiency = (chillers) => {
  return chillers.map(chiller => ({
    ...chiller,
    efficiency: 0.63 + ((1 - 0.63) / 20) * chiller.chillerAge,
  }));
};

// Rating
const calculateRating = (chillers) => {
  return chillers.map(chiller => ({
    ...chiller,
    rating: chiller.efficiency * chiller.chillerCapacity,
  }));
};

// Present usage
const calculatePU = (chillers) => {
  return chillers.map(chiller => ({
    ...chiller,
    presentUsage: chiller.rating * chiller.efficiency,
  }));
};

// Monthly usage calculation using tariff options from SavingsForm
//calculate monthly usage per month depending work days per week -> month
const calculateMonth = (chillers, tariffOptions, tariffOption) => {
  const selectedTariffDetails = tariffOptions.find(option => option.value === tariffOption);

  if (!selectedTariffDetails) {
    throw new Error('Selected tariff option not found');
  }

  return chillers.map(chiller => {
    let monthlyUsage = 0;
    if (selectedTariffDetails.details.cost) {
      selectedTariffDetails.details.cost.forEach(cost => {
        if (cost.type === 'peak' || cost.type === 'off-peak') {
          monthlyUsage += chiller.presentUsage * cost.rate * 30;
        } else if (cost.range) {
          // Calculate based on range if present
          const [min, max] = cost.range.split('-').map(value => parseInt(value.replace('kwh', '').replace('>', ''), 10));
          if (chiller.presentUsage >= min && (!max || chiller.presentUsage <= max)) {
            monthlyUsage += chiller.presentUsage * cost.rate * 30;
          }
        } else {
          monthlyUsage += chiller.presentUsage * cost.rate * 30;
        }
      });
    }

    return {
      ...chiller,
      monthlyUsage,
    };
  });
};
//8 percent saving
const calculateEightPercent = (chillers) => {
  return chillers.map(chiller => ({
    ...chiller,
    eightPercent: chiller.monthlyUsage * (1 - 0.08),
  }));
};
//10 percent saving
const calculateTenPercent = (chillers) => {
  return chillers.map(chiller => ({
    ...chiller,
    tenPercent: chiller.monthlyUsage * (1 - 0.1),
  }));
};

//12 percent saving
const calculateTwelve = (chillers) => {
  return chillers.map(chiller => ({
    ...chiller,
    twelvePercent: chiller.monthlyUsage * (1 - 0.12),
  }));
};

//8 percent saved
const calculateSaving8Percent = (chillers) => {
  return chillers.map(chiller => ({
    ...chiller,
    Saving8Percent: chiller.monthlyUsage - chiller.eightPercent,
  }));
};
//10 percent saved
const calculateSaving10Percent = (chillers) => {
  return chillers.map(chiller => ({
    ...chiller,
    Saving10Percent: chiller.monthlyUsage - chiller.tenPercent,
  }));
};
//12 percent saved
const calculateSaving12Percent = (chillers) => {
  return chillers.map(chiller => ({
    ...chiller,
    Saving12Percent: chiller.monthlyUsage - chiller.twelvePercent,
  }));
};
//total saved perYear for 8 percent
const calculateTotalSaved8 = (chillers) => {
  return chillers.map(chiller => ({
    ...chiller,
    TotalSaved8: chiller.Saving8Percent * 12,
  }));
};
//total saved perYear for 10 percent
const calculateTotalSaved10 = (chillers) => {
  return chillers.map(chiller => ({
    ...chiller,
    TotalSaved10: chiller.Saving10Percent * 12,
  }));
};
//total saved perYear for 12 percent
const calculateTotalSaved12 = (chillers) => {
  return chillers.map(chiller => ({
    ...chiller,
    TotalSaved12: chiller.Saving12Percent * 12,
  }));
};



export default calculateChillerData;
