import React, { useState, useEffect } from 'react';
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox"; // Adjust the import path according to your project structure
import MKButton from 'components/MKButton';
import MKTypography from 'components/MKTypography';
import Engineer from 'assets/images/MaximizeeService1.jpg';
import Container from "@mui/material/Container";
import logo from 'assets/images/logos/removed/MaximizEE.png';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import emailjs from 'emailjs-com';
import Alert from '@mui/material/Alert';

function Energy() {
  const [modalOpen, setModalOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    companyName: '',
    email: '',
    phone: '',
    message:'',
  });
  const [alertVisible, setAlertVisible] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => {
    setModalOpen(false);
    setTimeout(() => {
      document.activeElement.blur();
    }, 0);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      name: formData.name,
      companyName: formData.companyName,
      email: formData.email,
      phone: formData.phone,
      message: formData.message
    };

    emailjs.send('service_m7kd5pf', 'template_maximize', templateParams, '1bmxxZrEdC65PCJD9')
      .then((response) => {
        console.log('Email successfully sent!', response.status, response.text);
        setFormData({
          name: '',
          companyName: '',
          email: '',
          phone: '',
          message:'',
        });
        setAlertVisible(true);
        setTimeout(() => setAlertVisible(false), 5000);
        handleClose();
      }, (err) => {
        console.log('Failed to send email. Error: ', err);
      });
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY + window.innerHeight;
      const fadeInElements = document.querySelectorAll('.fade-in');
      fadeInElements.forEach(el => {
        const elementPosition = el.getBoundingClientRect().top + window.scrollY;
        if (scrollPosition > elementPosition) {
          el.classList.add(el.dataset.animation);
        }
      });
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <Container>
      <Grid container sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%",
      }}>
        <Grid
          item
          xs={12}
          sx={{
            height: "100%",
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            backgroundImage: `linear-gradient(to left, rgba(0, 0, 0, 0), rgba(0, 0, 128, 0.7), #455883), url(${Engineer})`, // MaximizeEE specific image
            backgroundSize: "cover",
            backgroundPosition: "left",
            minHeight: { xs: "400px", md: "550px" }, // Ensure minimum height for mobile
          }}
        >
          <Grid item xs={12} md={5} sx={{ paddingLeft: { md: 1 }, textAlign: { xs: 'center', md: 'left' } }}> 
            <MKBox
              sx={{
                backgroundColor: "rgba(255, 255, 255, 0.6)", 
                padding: { xs: 2, md: 3 },
                backdropFilter: "blur(4px)",
                height: { xs: "auto", md: "550px" }, 
                width: { xs: "90%", md: "450px" }, // Adjust width for mobile
                my: { xs: 2, md: 4 },
                mx: { xs: "auto", md: "30px" }, // Center horizontally for mobile
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
              }}
            >
              <MKBox mb={5}>
                <MKBox position="relative" display="inline-block">
                  <MKBox
                    component="img"
                    src={logo}
                    alt="Maximizee"
                    sx={{
                      maxWidth: "100%",
                      display: "block",
                      mx: { xs: "auto", md: 0 },
                      mt: -6,
                    }}
                  />
                </MKBox>
                <MKTypography
                  variant="body2"
                  color="white"
                  sx={({ breakpoints }) => ({
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    textAlign: { xs: "center", md: "left" },
                    mt: { xs: -5, md: -18 },
                    [breakpoints.down("md")]: {
                      fontSize: "1rem",
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: "0.875rem",
                    },
                  })}
                >
                  MaximizeEE is a project focused on optimizing energy use in chiller systems, which are major energy consumers in large facilities. The goal is to run these systems more efficiently, helping to cut energy costs and reduce environmental impact.
                </MKTypography>
                <MKBox
                  sx={{
                    display: "flex",
                    justifyContent: { xs: "center", md: "flex-start" }, // Center button on mobile
                    mt: 3.5,
                  }}
                >
                  <MKButton
                    variant="contained"
                    sx={{
                      backgroundColor: "#43abc1",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#36a2b3",
                        boxShadow: "0px 6px 15px rgba(0, 123, 255, 0.5)",
                        borderColor: "#36a2b3",
                      },
                      textTransform: "none",
                      fontWeight: "bold",
                      display: "block",
                      px: 4,
                      py: 1.5,
                      borderRadius: "8px",
                      transition: "all 0.3s ease-in-out",
                    }}
                    onClick={handleOpen}
                  >
                    GET TO KNOW MORE
                  </MKButton>
                </MKBox>
              </MKBox>
            </MKBox>
          </Grid>
        </Grid>
      </Grid>
      {/* Modal for Contact Form */}
      <Modal
        open={modalOpen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <MKBox
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: 300, md: 400 },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: '8px',
          }}
        >
          <MKTypography variant="h6" component="h2" mb={2}>
            Quote Us For MaximizeEE
          </MKTypography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Company Name"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              margin="normal"
              required
            />
            <MKButton type="submit"
              variant="contained"
              sx={{
                backgroundColor: '#59535c',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#59535c',
                },
                textTransform: 'none',
                mt: 2,
              }}>
              SUBMIT
            </MKButton>
            {alertVisible && <Alert severity="success">There you go! Your email is on its way to SquareCloud</Alert>}
          </form>
        </MKBox>
      </Modal>
    </Container>
  );
}

export default Energy;
