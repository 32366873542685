import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Grid, Card } from '@mui/material';
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import MKInput from 'components/MKInput';
import MKButton from 'components/MKButton';
import Select from 'react-dropdown-select';
// Material Kit 2 React example components
import DefaultNavbar from "examples/Navigation/Navbar"
import DefaultFooter from "examples/Footers/DefaultFooter";
//routes react
import routes from "routes";
import footerRoutes from "footer.routes";
//firebase db
import { db } from 'firebase.js';
import { collection, addDoc } from 'firebase/firestore';

function ChillerForm() {
    const initialChillerState = {
        noChiller: '',
        chillerMode: '',
        chillerMake: '',
        chillerSerial: '',
        chillerCapacity: '',
        chillerYear: '',
        chillerStart: '',
        chillerStop: '',
        chillerFLA: '',
        chillerSet: '',
        chillerWaterSup: '',
        chillerWaterRe: '',
        chillerWaterFlow: '',
        condenserSupply: '',
        condenserWaterRe: '',
        condesnerWater: '',
        flowRate: '',
        compressorModel: '',
        compressorRate: '',
        compressorType: '',
        frequency: '',
        thirdParty: '',
        rpm: '',
        powerFactor: ''
    };

    const [formData, setFormData] = useState([initialChillerState]);
    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate();

    const handleInputChange = (event, index) => {
        const { name, value } = event.target;
        const updatedChillers = formData.map((chiller, i) => i === index ? { ...chiller, [name]: value } : chiller);
        setFormData(updatedChillers);
    };

    const handleSelectChange = (selected, name, index) => {
        const value = selected[0].value; // Assuming single select
        const updatedChillers = formData.map((chiller, i) => i === index ? { ...chiller, [name]: value } : chiller);
        setFormData(updatedChillers);
    };

    const addChiller = () => {
        setFormData([...formData, initialChillerState]);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (currentStep === 5) {
            try {
                await addDoc(collection(db, 'ChillerForm'), { chillers: formData });
                alert('Form submitted successfully!');
                localStorage.setItem('formData', JSON.stringify(formData));
                navigate('/AhuBms');
            } catch (error) {
                console.error('Error adding document: ', error);
                alert('Error submitting form. Please try again.');
            }
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    return (
        <>
            <DefaultNavbar routes={routes} transparent light />
           

            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: 0,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <MKBox px={1} width="100%" mx="auto" position="relative" zIndex={2}>
                    <Grid container spacing={1} alignItems="center" justifyContent="center">
                        <Grid item xs={11} sm={9} md={7} lg={6} xl={5}>
                            <MKBox variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={4}
                                p={2}
                                mb={1}
                                textAlign="center">
                                <MKTypography variant="h4" fontWeight="medium" color="white" textAlign="center" mt={1}>
                                    Chiller Details
                                </MKTypography>
                            </MKBox>
                            <MKBox pt={4} pb={4} px={4}>
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={1}>
                                        {formData.map((chiller, index) => (
                                            <Grid item xs={12} key={index}>
                                                {currentStep === 1 && (
                                                    <>
                                                        <MKBox mb={2}>
                                                            <MKTypography variant="body1">{`Chiller ${index + 1}: ${chiller.noChiller}`}</MKTypography>
                                                            <MKTypography variant="body1" color="textPrimary" mb={1}>
                                                                Chiller Operation Mode
                                                            </MKTypography>
                                                            <Select
                                                                options={[{ value: 'duty', label: 'Duty' }, { value: 'standby', label: 'Standby' }]}
                                                                onChange={(selected) => handleSelectChange(selected, 'chillerMode', index)}
                                                                values={chiller.chillerMode ? [{ value: chiller.chillerMode, label: chiller.chillerMode }] : []}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKTypography variant="body1" color="textPrimary" mb={1}>
                                                                Chiller Make
                                                            </MKTypography>
                                                            <Select
                                                                options={[
                                                                    { value: 'carrier', label: 'Carrier' },
                                                                    { value: 'trane', label: 'Trane' },
                                                                    { value: 'daikin', label: 'Daikin' },
                                                                    { value: 'johnshon', label: 'Johnson' },
                                                                    { value: 'mitsubishi', label: 'Mitsubishi' },
                                                                    { value: 'danfoss', label: 'Danfoss' },
                                                                    { value: 'media', label: 'Media' },
                                                                    { value: 'smardt', label: 'Smardt' },
                                                                    { value: 'climaveneta', label: 'Climaveneta' },
                                                                    { value: 'scy', label: 'SCY' }
                                                                ]}
                                                                onChange={(selected) => handleSelectChange(selected, 'chillerMake', index)}
                                                                values={chiller.chillerMake ? [{ value: chiller.chillerMake, label: chiller.chillerMake }] : []}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                variant="outlined"
                                                                label="Chiller Serial Number"
                                                                fullWidth
                                                                name="chillerSerial"
                                                                value={chiller.chillerSerial}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                type="number"
                                                                label="Chiller Capacity"
                                                                fullWidth
                                                                name="chillerCapacity"
                                                                value={chiller.chillerCapacity}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                variant="outlined"
                                                                label="Chiller Year Installed"
                                                                fullWidth
                                                                name="chillerYear"
                                                                value={chiller.chillerYear}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                type="time"
                                                                label="Chiller Start Time"
                                                                fullWidth
                                                                name="chillerStart"
                                                                value={chiller.chillerStart}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                type="time"
                                                                label="Chiller Stop Time"
                                                                fullWidth
                                                                name="chillerStop"
                                                                value={chiller.chillerStop}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                    </>
                                                )}
                                                {currentStep === 2 && (
                                                    <>
                                                        <MKTypography variant="body1">{`Chiller ${index + 1}: ${chiller.noChiller}`}</MKTypography>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                type="number"
                                                                label="Chiller Operating FLA"
                                                                fullWidth
                                                                name="chillerFLA"
                                                                value={chiller.chillerFLA}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                type="number"
                                                                label="Chiller Set Point"
                                                                fullWidth
                                                                name="chillerSet"
                                                                value={chiller.chillerSet}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                variant="outlined"
                                                                label="Chiller Water Supply Temperature"
                                                                fullWidth
                                                                name="chillerWaterSup"
                                                                value={chiller.chillerWaterSup}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                variant="outlined"
                                                                label="Chilled Water Return Temperature"
                                                                fullWidth
                                                                name="chillerWaterRe"
                                                                value={chiller.chillerWaterRe}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                type="number"
                                                                label="Chilled Water Flowrate (m3hr / gpm)"
                                                                fullWidth
                                                                name="chillerWaterFlow"
                                                                value={chiller.chillerWaterFlow}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                    </>
                                                )}
                                                {currentStep === 3 && (
                                                    <>
                                                        <MKTypography variant="body1">{`Chiller ${index + 1}: ${chiller.noChiller}`}</MKTypography>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                type="number"
                                                                label="Condenser Water Supply Temp(F/C)"
                                                                fullWidth
                                                                name="condenserSupply"
                                                                value={chiller.condenserSupply}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                type="number"
                                                                label="Condenser Water Supply Return Temp(F/C)"
                                                                fullWidth
                                                                name="condenserWaterRe"
                                                                value={chiller.condenserWaterRe}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                variant="outlined"
                                                                label="Condenser Water"
                                                                fullWidth
                                                                name="condesnerWater"
                                                                value={chiller.condesnerWater}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                type="number"
                                                                label="Flowrate (m3hr/gpm)"
                                                                fullWidth
                                                                name="flowRate"
                                                                value={chiller.flowRate}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                variant="outlined"
                                                                label="Compressor Model"
                                                                fullWidth
                                                                name="compressorModel"
                                                                value={chiller.compressorModel}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                    </>
                                                )}
                                                {currentStep === 4 && (
                                                    <>
                                                        <MKTypography variant="body1">{`Chiller ${index + 1}: ${chiller.noChiller}`}</MKTypography>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                type="number"
                                                                label="Compressor Rate (kW)"
                                                                fullWidth
                                                                name="compressorRate"
                                                                value={chiller.compressorRate}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                variant="outlined"
                                                                label="Compressor Type"
                                                                fullWidth
                                                                name="compressorType"
                                                                value={chiller.compressorType}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                    </>
                                                )}
                                                {currentStep === 5 && (
                                                    <>
                                                        <MKTypography variant="body1">{`Chiller ${index + 1}: ${chiller.noChiller}`}</MKTypography>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                type="number"
                                                                label="Power Factor (pf)"
                                                                fullWidth
                                                                name="powerFactor"
                                                                value={chiller.powerFactor}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                type="number"
                                                                label="Frequency(Hz)"
                                                                fullWidth
                                                                name="frequency"
                                                                value={chiller.frequency}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <MKInput
                                                                type="number"
                                                                label="RPM"
                                                                fullWidth
                                                                name="rpm"
                                                                value={chiller.rpm}
                                                                onChange={(event) => handleInputChange(event, index)}
                                                            />
                                                        </MKBox>
                                                        <MKBox mb={2}>
                                                            <label>Third Party System Interface (BMS)Available?</label>
                                                            <Select
                                                                options={[{ value: 'yes', label: 'Yes' }, { value: 'no', label: 'No' }]}
                                                                onChange={(selected) => handleSelectChange(selected, 'thirdParty', index)}
                                                                values={chiller.thirdParty ? [{ value: chiller.thirdParty, label: chiller.thirdParty }] : []}
                                                            />
                                                        </MKBox>
                                                    </>
                                                )}
                                            </Grid>
                                        ))}
                                    </Grid>
                                    <MKButton type="button" variant="outlined" color="dark" onClick={addChiller}>
                                        Add Chiller
                                    </MKButton>
                                    <MKButton mt={4} mr={2} mb={1} type="button" variant="outlined" color="dark" onClick={handlePrevious}>
                                        Previous
                                    </MKButton>
                                    <MKButton mt={4} ml={2} mb={1} type="submit" variant="gradient" color="dark">
                                        {currentStep === 5 ? 'Submit' : 'Next'}
                                    </MKButton>

                                </form>
                            </MKBox>
                        </Grid>
                    </Grid>
                </MKBox>
            </Card>
            <MKBox pt={6} px={0} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
}

export default ChillerForm;
