import React from 'react';
import { Link } from "react-router-dom";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import PropTypes from "prop-types";

function DefaultFooter({ content }) {
  const { brand, menus, copyright } = content;

  return (
    <MKBox component="footer" sx={{ backgroundColor: "#00263b", color: "white" }}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} sx={{ ml: "auto", mb: 3 }}>
            <MKBox sx={{ display: "flex", alignItems: "center", color: "white" }}>
              <Link to={brand.route} style={{ display: "flex", alignItems: "center", color: "white" }}>
                <MKBox component="img" src={brand.image} alt={brand.name} maxWidth="3rem" mr={3} />
                <MKTypography variant="h6" color="white">{brand.name}</MKTypography>
              </Link>
            </MKBox>
          </Grid>
          <Grid item xs={5} sx={{ ml: "auto", mb: 3 }}>
            <Grid container spacing={2}>
              {menus.map((menu, index) => (
                <Grid item key={index}>
                  {menu.route ? (
                    <Link to={menu.route} style={{ color: "white" }}>
                      <MKTypography variant="button" fontWeight="regular" color="white">
                        {menu.name}
                      </MKTypography>
                    </Link>
                  ) : (
                    <MKTypography variant="button" fontWeight="regular" color="white">
                      {menu.name}
                    </MKTypography>
                  )}
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            <MKTypography variant="body2" color="#fdfeff">
              {copyright}
            </MKTypography>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

DefaultFooter.propTypes = {
  content: PropTypes.shape({
    brand: PropTypes.shape({
      name: PropTypes.string.isRequired,
      image: PropTypes.string.isRequired,
      route: PropTypes.string.isRequired,
    }).isRequired,
    menus: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string.isRequired,
        route: PropTypes.string,
      })
    ).isRequired,
    copyright: PropTypes.node.isRequired,
  }).isRequired,
};

export default DefaultFooter;
