import React, { useState, useEffect, useRef } from "react";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import { FaRobot } from "react-icons/fa";
import { IoChevronDownCircleOutline } from "react-icons/io5";
import config from "config.js";
import MessageParser from "chatbot/MessageParser.js";
import ActionProvider from "chatbot/ActionProvider.js";
import WhatsappChat from "whatsapp/WhatsappChat.js";

// @mui/material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { InView } from 'react-intersection-observer';
import Fade from '@mui/material/Fade';

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import Divider from '@mui/material/Divider';

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navigation/Navbar";
import DefaultFooter from "examples/Footers/Footer/footer";

// Presentation page sections
import Information from "pages/Presentation/sections/Information";
import Hero from "pages/Hero/HeroSection";
import Counters from "pages/Presentation/sections/Counters";
import NavServices from "pages/Presentation/sections/NavServices";
import Newsletter from "pages/Presentation/sections/Newsletter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import chiler from "assets/images/Pics _Chiller/chiller.jpg";

const imageStyles1 = {
  objectFit: "cover", // Maintain aspect ratio and cover the container
  width: "100%", // Full width of the container
  height: "100%", // Full height of the container to match the text
  transition: "transform 0.2s ease-in-out",
  "&:hover": {
    transform: "scale(1.1)", // Zoom on hover
  },
};


function Presentation() {
  const [isChatbotOpen, setIsChatbotOpen] = useState(false);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const textRef = useRef(null);

  const toggleChatbot = () => {
    setIsChatbotOpen((prevState) => !prevState);
  };

  const scrollToNextSection = () => {
    const currentScroll = window.scrollY;
    const targetScroll = currentScroll + window.innerHeight;
    window.scrollTo({
      top: targetScroll,
      behavior: "smooth",
    });

    setIsButtonVisible(false);
    setTimeout(() => {
      setIsButtonVisible(true);
    }, 500);
  };

  const handleScroll = () => {
    if (window.scrollY === 0) {
      setIsButtonVisible(true);
    } else {
      setIsButtonVisible(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      <Hero />
      <DefaultNavbar routes={routes} transparent light />

      <Card
        sx={{
          p: 0,
          mx: { xs: 0, lg: 0 },
          mt: 0,
          mb: 0,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        {/* First Section */}
        <Grid container justifyContent="center">
          <Container>
            <Grid container alignItems="center">
              <Grid item xs={12} md={12} mt={{ xs: 2, sm: 4, md: 13 }} mb={{ md: 9.5 }}>
                <MKTypography
                  ref={textRef}
                  variant="h3"
                  textAlign="center"
                  mt={4}
                  mb={1}
                  sx={({ breakpoints, typography: { size } }) => ({
                    color: "#59535c",
                    fontFamily: "'Caudex', serif",
                    fontWeight: "bold",
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: size["2xl"],
                    },
                  })}
                >
                  &quot;Transforming Energy Efficiency in Buildings for a Sustainable Future&quot;
                </MKTypography>
                <Divider
                  sx={{
                    margin: { xs: '20px auto', md: '10px 0' },
                    width: { xs: '50%', md: '100%' },
                    height: { xs: '2px', md: '3px' },
                    backgroundColor: '#43abc1',
                  }}
                />
                <MKTypography
                  variant="body2"
                  color="#59535c"
                  mb={0}
                  sx={({ breakpoints }) => ({
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    textAlign: "center",
                    mt: 0,
                    [breakpoints.down("md")]: {
                      fontSize: "1rem",
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: "0.875rem",
                    },
                  })}
                >
                  We Deliver Lean, Autonomous Building Solutions and Improve the Lives of People.
                </MKTypography>
              </Grid>
            </Grid>

            <InView threshold={0.1} triggerOnce>
      {({ inView, ref }) => (
        <div ref={ref}>
          <Fade in={inView} timeout={2000}>
            {/* Section with Increased Column Spacing */}
            <Grid
              container
              alignItems="center"
              rowSpacing={3}         // Maintains vertical spacing
              columnSpacing={6}      // Increases horizontal spacing between image and text
              sx={{ height: "auto" }}
              mt={4}
              mb={{ xs: -3, md: 2 }}
            >
              {/* Image Section */}
              <Grid
                item
                xs={12}
                md={5}
                order={{ xs: 1, md: 2 }} // Image first on mobile, second on desktop
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center", // Center on mobile
                  height: "100%",
                }}
              >
                <MKBox
                  component="img"
                  sx={{
                    ...imageStyles1,
                    height: "auto",
                    maxHeight: "100%",
                    maxWidth: "100%",
                  }}
                  src={chiler}
                  alt="chiller"
                />
              </Grid>

              {/* Text Section */}
              <Grid
                item
                xs={12}
                md={7}
                order={{ xs: 2, md: 1 }} // Text second on mobile, first on desktop
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  height: "100%",
                  mb: { xs: "30px" }
                }}
              >
                <MKTypography
                  variant="h3"
                  sx={({ breakpoints, typography: { size } }) => ({
                    color: "#59535c",
                    textAlign: {
                      xs: "center",
                      md: "left",
                    },
                    [breakpoints.down("md")]: {
                      fontSize: size["3xl"],
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: size["2xl"],
                    },
                  })}
                  mb={1}
                >
                  Chiller MaximizEE: AI-Powered Heat Load Analytics for Optimal Energy Efficiency
                </MKTypography>
                <Divider
                  sx={{
                    margin: { xs: "20px auto", md: "10px 0" },
                    width: { xs: "50%", md: "30%" },
                    height: { xs: "2px", md: "3px" },
                    backgroundColor: "#43abc1",
                  }}
                />
                <MKTypography
                  variant="body2"
                  color="#59535c"
                  mb={0}
                  maxWidth = {{md: 650}}
                  sx={({ breakpoints }) => ({
                    fontSize: "1.2rem",
                    lineHeight: "1.75",
                    textAlign: {
                      xs: "center",
                      md: "left",
                    },
                    mt: 4,
                    [breakpoints.down("md")]: {
                      fontSize: "1rem",
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: "0.875rem",
                    },
                  })
                }
                >
                  Chiller MaximizEE is an advanced, AI-driven software solution that optimizes the energy consumption of chiller plant facilities in real-time. By continuously analyzing heat load and energy demand, the system ensures that your chiller operates at its ideal &quot;Sweet Spot&quot;&mdash;minimizing energy waste and maximizing efficiency.
                  <br />
                  <br />
                  With autonomous control, Chiller MaximizEE delivers the precise amount of energy required to meet real-time demand, eliminating unnecessary consumption and reducing operational costs. The platform integrates seamlessly with any existing Building Automation System (BAS) or Facilities Control Management System (FCMS), complementing your current infrastructure for effortless optimization.
                </MKTypography>
              </Grid>
            </Grid>
          </Fade>
        </div>
      )}
    </InView>

          </Container>

          {/* NavServices Section */}
          <InView threshold={0.1} triggerOnce>
            {({ inView, ref }) => (
              <div ref={ref}>
                <Fade in={inView} timeout={2000}>
                  <Grid
                    sx={{
                      Width: { md: "1264px" },
                    }}
                  >
                    <NavServices />
                  </Grid>
                </Fade>
              </div>
            )}
          </InView>


        </Grid>
        {/* Information Section */}
        <InView threshold={0.1} triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref}>
              <Fade in={inView} timeout={2000}>
                <Container sx={{ mt: { xs: 4, md: 18 }, mb: { md: 4 } }}>
                  <Information />
                </Container>
              </Fade>
            </div>
          )}
        </InView>


        <Box sx={{ mb: { xs: 8, md: 18.5 } }} /> {/* Bottom margin for spacing */}

        {/* Newsletter Section */}
        <InView threshold={0.1} triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref}>
              <Fade in={inView} timeout={2000}>
                <Grid container>
                  <Grid item xs={12}>
                    <Newsletter />
                  </Grid>
                </Grid>
              </Fade>
            </div>
          )}
        </InView>

        {isChatbotOpen && (
          <div
            id="chatbot-container"
            style={{
              position: "fixed",
              bottom: "80px",
              left: "20px",
              zIndex: 1000,
              backgroundColor: "white",
              boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
              borderRadius: "10px",
              padding: "10px",
            }}
          >
            <Chatbot
              config={config}
              messageParser={MessageParser}
              actionProvider={ActionProvider}
            />
          </div>
        )}
        {isButtonVisible && (
          <MKBox
            sx={{
              position: "fixed",
              bottom: 10,
              left: "50%",
              transform: "translateX(-50%)",
              zIndex: 1000,
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "50px",
              height: "50px",
              borderRadius: "0%",
              boxShadow: "0px rgba(0, 0, 0, 0.1)",
              transition: "transform 0.2s ease-in-out",
              "&:hover": {
                transform: "translateX(-50%) scale(1.1)",
              },
            }}
            onClick={scrollToNextSection}
          >
            <IoChevronDownCircleOutline color="#f9f9f9" size="46px" opacity="100%" />
          </MKBox>
        )}
        <MKBox
          sx={{
            position: "fixed",
            bottom: 20,
            left: 20,
            zIndex: 1000,
            cursor: "pointer",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "50px",
            height: "50px",
            backgroundColor: "#007BFF",
            borderRadius: "50%",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
            transition: "transform 0.2s ease-in-out",
            "&:hover": {
              transform: "scale(1.1)",
            },
          }}
          onClick={toggleChatbot}
        >
          <FaRobot color="white" size="24px" />
        </MKBox>

        {/* Add top margin to Counters */}
        <InView threshold={0.1} triggerOnce>
          {({ inView, ref }) => (
            <div ref={ref}>
              <Fade in={inView} timeout={2000}>
                <MKBox mt={{ xs: 8, md: 18 }}>
                  <Counters />
                </MKBox>
              </Fade>
            </div>
          )}
        </InView>



        <MKBox pt={0} px={0} mt={{ md: 18 }}>
          <DefaultFooter light content={footerRoutes} />
        </MKBox>

        <WhatsappChat />

      </Card>
    </>

  );
}

export default Presentation;
