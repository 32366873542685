import React, { useState } from "react";
import { FaWhatsapp } from "react-icons/fa";
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

const WhatsappChat = () => {
    const [isOpen, setIsOpen] = useState(false);
    const phoneNumber = "+60102529031"; // Replace with your WhatsApp phone number
    const message = "Hello, I would like to ask about..."; // Default message

    const toggleChat = () => setIsOpen(!isOpen);

    return (
        <>
            <MKBox
                sx={{
                    position: "fixed",
                    bottom: 20,
                    right: 20,
                    zIndex: 1000,
                    cursor: "pointer",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "50px",
                    height: "50px",
                    backgroundColor: "#25D366",
                    borderRadius: "50%",
                    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                    transition: "transform 0.2s ease-in-out",
                    '&:hover': {
                        transform: "scale(1.1)"
                    }
                }}
                onClick={toggleChat}
            >
                <FaWhatsapp color="black" size="24px" />
            </MKBox>
            {isOpen && (
                <MKBox
                    sx={{
                        position: "fixed",
                        bottom: 80,
                        right: 20,
                        width: "300px",
                        backgroundColor: "white !important", // Ensure this is solid white
                        boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
                        borderRadius: "10px",
                        padding: "10px",
                    }}
                >
                    <MKTypography
                        variant="h3"
                        color="#59535c"
                        mb={0}
                        sx={({ breakpoints }) => ({
                            fontSize: "1.2rem",
                            lineHeight: "1.5",
                            textAlign: "center",
                            mt: 0,
                            [breakpoints.down("md")]: {
                                fontSize: "1rem",
                            },
                            [breakpoints.down("sm")]: {
                                fontSize: "0.875rem",
                            },
                        })}>Start a chat with us:</MKTypography>
                    <MKBox
                        component="textarea"
                        rows="4"
                        value={message}
                        readOnly
                        sx={({ breakpoints }) => ({
                            fontSize: "1rem",
                            lineHeight: "1",
                            textAlign: "center",
                            mt: 0,
                            color: "#59535c", // Apply the same color
                            width: "100%",
                            margin: "10px 0",
                            border: "1px solid #ccc", // Optional: add a border
                            borderRadius: "4px", // Optional: add border-radius for a rounded effect
                            padding: "8px", // Optional: add padding inside the textarea
                            [breakpoints.down("md")]: {
                                fontSize: "1rem",
                            },
                            [breakpoints.down("sm")]: {
                                fontSize: "0.875rem",
                            },
                        })}
                    />
                    <MKButton
                        variant="contained"
                        color="success"
                        onClick={() => window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, "_blank")}
                    >
                        Send
                    </MKButton>
                </MKBox>
            )}
        </>
    );
};

export default WhatsappChat;