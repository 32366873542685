import React, { useState } from "react";
import { Grid, Dialog, DialogTitle, DialogContent } from "@mui/material";
import MKTypography from "components/MKTypography";
import MKBox from "components/MKBox";
import Lottie from "react-lottie";

// Import Vision and Mission animated icons
import VisionAnimation from "assets/images/VisionandMission-Icons/Vision_1.json";
import MissionAnimation from "assets/images/VisionandMission-Icons/Mission_1.json";

const values = [
  {
    name: "Vision",
    shortText: "Our Vision",
    fullText:
      "To revolutionize the global building industry by creating intelligent, AI-driven environments that achieve carbon neutrality, empowering businesses to embrace sustainability and shaping a greener future for all.",
    animation: VisionAnimation, // Vision animated icon
  },
  {
    name: "Mission",
    shortText: "Our Mission",
    fullText:
      "We develop cutting-edge IoT sensors and AI-driven platforms to aggregate and analyze building energy data, enabling businesses to reduce energy consumption, optimize performance, and achieve their sustainability goals. Through innovative technology, data intelligence, and proactive energy management solutions, we empower companies to transition toward carbon-neutral operations and contribute to a sustainable future.",
    animation: MissionAnimation, // Mission animated icon
  },
];

const MissionVision = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedValue, setSelectedValue] = useState(null);
  const [hoveredIndex, setHoveredIndex] = useState(null); // Track hover index

  const handleClickOpen = (value) => {
    setSelectedValue(value);
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedValue(null);
  };

  const squareSize = 300; // Size of each square
  const containerPadding = 55; // Padding for container

  const defaultOptions = (animationData) => ({
    loop: true,
    autoplay: false, // Disable autoplay to trigger on hover
    animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  });

  return (
    <>
      {/* Grid Layout for Vision and Mission Sections */}
      <MKBox
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
          mt: 10,
          mb: 10,
          px: `${containerPadding}px`,
        }}
      >
        <Grid container spacing={5} justifyContent="center">
          {/* Map through Vision and Mission values */}
          {values.map((value, index) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={index}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
              onMouseEnter={() => setHoveredIndex(index)} // Set hover index
              onMouseLeave={() => setHoveredIndex(null)} // Reset hover index
            >
              <MKBox
                sx={{
                  width: `${squareSize}px`,
                  height: `${squareSize}px`,
                  backgroundColor: "#8cbcdb",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0 8px 16px rgba(0,0,0,0.1)",
                  cursor: "pointer",
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
                onClick={() => handleClickOpen(value)}
              >
                {/* White Circle Container */}
                <MKBox
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "50%",
                    width: "120px",
                    height: "120px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    mb: 2,
                  }}
                >
                  {/* Lottie Animation */}
                  <Lottie
                    options={defaultOptions(value.animation)}
                    height={80}
                    width={80}
                    isStopped={hoveredIndex !== index} // Play animation on hover
                  />
                </MKBox>

                <MKTypography
                  variant="h5"
                  sx={{
                    color: "#fff",
                    fontWeight: "bold",
                    mb: 2,
                  }}
                >
                  {value.shortText}
                </MKTypography>
                <MKTypography
                  variant="body2"
                  sx={{
                    color: "#fff",
                    px: 2,
                    textAlign: "center",
                  }}
                >
                  {value.fullText.slice(0, 80)}... {/* Shortened description */}
                </MKTypography>
              </MKBox>
            </Grid>
          ))}
        </Grid>
      </MKBox>

      {/* Dialog Box for showing full text */}
      <Dialog
        open={openDialog}
        onClose={handleClose}
        PaperProps={{
          sx: {
            borderRadius: "20px",
            boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)",
            padding: 4,
          },
        }}
        maxWidth="sm"
      >
        <DialogTitle>
          <MKTypography variant="h5" fontWeight="bold">
            {selectedValue?.shortText}
          </MKTypography>
        </DialogTitle>
        <DialogContent>
          <MKTypography variant="body2" color="textSecondary">
            {selectedValue?.fullText}
          </MKTypography>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default MissionVision;
