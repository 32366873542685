import React, { useState } from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from '@mui/material/Divider';
import MKButton from 'components/MKButton';
import WhatsappChat from "whatsapp/WhatsappChat.js";

import TextField from '@mui/material/TextField';
import emailjs from 'emailjs-com'; // Import EmailJS
import Alert from '@mui/material/Alert';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

import DefaultNavbar from "examples/Navigation/Navbar";
import DefaultFooter from "examples/Footers/Footer/footer";

import routes from "routes";
import footerRoutes from "footer.routes";

import TopPic from "assets/images/GreenBOSMain1.jpg";
import Whole from "assets/images/GreenBOSMain2.jpg";
import Whole2 from "assets/images/GreenBOSMain3.jpg";
import Whole3 from "assets/images/GreenBOSMain4.jpg";
import Whole4 from "assets/images/GreenBOSMain5.jpg";

import GreenBOSPic from "assets/images/logos/removed/GreenBOS.png";

const imageStyles = {
  width: "100%",            // Ensures full width scaling
  maxWidth: "650px",         // Sets a slightly larger max width
  height: "auto",            // Maintains aspect ratio
  maxHeight: "400px",        // Ensures images don't exceed a specific height
  objectFit: "cover",        // Ensures the image covers the entire container without distortion
  margin: "auto",            // Centers the image horizontally
  display: "block",          // Ensures proper centering
};

function GreenBOS() {
  const [formData, setFormData] = useState({
    name: '',
    subject: '',
    email: '',
    message: ''
  });
  const [alertVisible, setAlertVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.name,
      subject: formData.subject,
      email: formData.email,
      message: formData.message
    };

    emailjs.send('service_m7kd5pf', 'template_8btqyep', templateParams, '1bmxxZrEdC65PCJD9')
      .then((response) => {
        console.log('Email successfully sent!', response.status, response.text);
        setFormData({
          name: '',
          subject: '',
          email: '',
          message: ''
        });
        setAlertVisible(true);
        setTimeout(() => setAlertVisible(false), 5000);
      }, (err) => {
        console.log('Failed to send email. Error: ', err);
      });
  };

  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      <MKBox position="relative" width="100%" height="55vh" display="flex" alignItems="center" justifyContent="center">
        <img
          src={TopPic}
          alt="Top Image"
          style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "top" }}
        />
        <MKBox
          component="img"
          src={GreenBOSPic}
          alt="GreenBOS Logo"
          sx={{
            position: "absolute",
            width: { xs: "70%", sm: "50%", md: "30%" },
            height: "auto",
            mt: 8,
            textAlign: "center",
            objectFit: "cover",
          }}
        />
      </MKBox>
      <Card
        sx={{
          p: 0,
          mx: { xs: 0, lg: 0 },
          mt: 0,
          mb: -10,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          borderRadius: 0,
        }}
      >
        <Container>

          <Container>
            {/* Section 1: Image (left) - Text (right) */}
            <Grid container alignItems="center" spacing={4} sx={{ my: 6 }}>
              {/* Image Section */}
              <Grid
                item
                xs={12}
                md={6}
                order={{ xs: 1, md: 1 }} // Image comes first on mobile and desktop
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MKBox
                  component="img"
                  sx={{
                    ...imageStyles,
                    height: "auto", // Ensure the image maintains aspect ratio
                    maxHeight: "100%", // Max height should match text container
                  }}
                  src={Whole}
                  alt="Whole"
                />
              </Grid>

              {/* Text Section */}
              <Grid
                item
                xs={12}
                md={6}
                order={{ xs: 2, md: 2 }} // Text comes after the image
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <MKBox sx={{mt:{md: 0}}}>
                  <MKTypography
                    variant="h3"
                    textAlign={{ xs: 'center', md: 'left' }}
                    mb={1}
                    sx={({ breakpoints, typography: { size } }) => ({
                      color: "#59535c",
                      [breakpoints.down("md")]: {
                        fontSize: size["2xl"],
                      },
                      [breakpoints.down("sm")]: {
                        fontSize: size["xl"],
                      },
                    })}
                  >
                    The Intelligent Sustainability Platform
                  </MKTypography>
                  <Divider
                    sx={{
                      width: { xs: '100%', md: '90%' },
                      height: '3px',
                      backgroundColor: '#43abc1',
                    }}
                  />
                  <MKTypography
                    variant="body2"
                    color="#59535c"
                    sx={({ breakpoints }) => ({
                      fontSize: "1.2rem",
                      lineHeight: "1.5",
                      textAlign: { xs: 'center', md: 'left' },
                      mt: 3,
                      [breakpoints.down("md")]: {
                        fontSize: "1rem",
                      },
                      [breakpoints.down("sm")]: {
                        fontSize: "0.875rem",
                      },
                    })}
                  >
                    GreenBOS is a dynamic platform designed to help businesses track and optimize sustainability efforts using real-time data from IoT sensors, systems, or file imports. It delivers actionable insights into energy usage, water consumption, waste management, and carbon emissions.
                    <br />
                    By driving operational efficiency and reducing carbon footprints, GreenBOS enables seamless sustainability management without the need for additional manpower intervention or manual input, making it a cost-effective solution for achieving sustainability goals.
                  </MKTypography>
                </MKBox>
              </Grid>
            </Grid>

            {/* Section 2 */}
            <Grid container alignItems="center" spacing={4} sx={{ my: 6 }}>
              {/* Image Section */}
              <Grid
                item
                xs={12}
                md={6}
                order={{ xs: 1, md: 1 }} // Image comes first
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MKBox component="img" sx={imageStyles} src={Whole2} alt="Whole2" />
              </Grid>

              {/* Text Section */}
              <Grid
                item
                xs={12}
                md={6}
                order={{ xs: 2, md: 2 }} // Text comes after the image
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <MKBox sx={{mt: {md: -20}}}>
                  <MKTypography
                    variant="h3"
                    textAlign={{ xs: 'center', md: 'left' }}
                    mb={1}
                    sx={({ breakpoints, typography: { size } }) => ({
                      color: "#59535c",
                      [breakpoints.down("md")]: {
                        fontSize: size["2xl"],
                      },
                      [breakpoints.down("sm")]: {
                        fontSize: size["xl"],
                      },
                    })}
                  >
                    Key Features and Data Insights
                  </MKTypography>
                  <Divider
                    sx={{
                      width: { xs: '100%', md: '90%' },
                      height: '3px',
                      backgroundColor: '#43abc1',
                    }}
                  />
                  <MKTypography
                    variant="body2"
                    color="#59535c"
                    sx={({ breakpoints }) => ({
                      fontSize: "1.2rem",
                      lineHeight: "1.5",
                      textAlign: { xs: 'center', md: 'left' },
                      mt: 3,
                      [breakpoints.down("md")]: {
                        fontSize: "1rem",
                      },
                      [breakpoints.down("sm")]: {
                        fontSize: "0.875rem",
                      },
                    })}
                  >
                    GreenBOS offers real-time tracking of energy, water, waste, and CO<sub>2</sub> emissions with detailed data on electricity, thermal energy, and recycling.
                  </MKTypography>
                </MKBox>
              </Grid>
            </Grid>

            {/* Section 3 */}
            <Grid container alignItems="center" spacing={4} sx={{ my: 6, mt: {md: -1}}}>
              {/* Image Section */}
              <Grid
                item
                xs={12}
                md={6}
                order={{ xs: 1, md: 1 }} // Image comes first
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MKBox component="img" sx={imageStyles} src={Whole3} alt="Whole3" />
              </Grid>

              {/* Text Section */}
              <Grid
                item
                xs={12}
                md={6}
                order={{ xs: 2, md: 2 }} // Text comes after the image
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <MKBox sx={{mt: {md: 2 }}}>
                  <MKTypography
                    variant="h3"
                    textAlign={{ xs: 'center', md: 'left' }}
                    mb={1}
                    sx={({ breakpoints, typography: { size } }) => ({
                      color: "#59535c",
                      [breakpoints.down("md")]: {
                        fontSize: size["2xl"],
                      },
                      [breakpoints.down("sm")]: {
                        fontSize: size["xl"],
                      },
                    })}
                  >
                    Driving Sustainability and Compliance
                  </MKTypography>
                  <Divider
                    sx={{
                      width: { xs: '100%', md: '90%' },
                      height: '3px',
                      backgroundColor: '#43abc1',
                    }}
                  />
                  <MKTypography
                    variant="body2"
                    color="#59535c"
                    sx={({ breakpoints }) => ({
                      fontSize: "1.2rem",
                      lineHeight: "1.5",
                      textAlign: { xs: 'center', md: 'left' },
                      mt: 3,
                      [breakpoints.down("md")]: {
                        fontSize: "1rem",
                      },
                      [breakpoints.down("sm")]: {
                        fontSize: "0.875rem",
                      },
                    })}
                  >
                    Sustainability Managers are crucial in driving sustainability goals and preparing the ESG Report, a key document for regulatory compliance. The ESG Report showcases businesses&apos; commitment to responsible practices and helps meet global sustainability goals, including certifications like LEED and BREEAM.
                    <br />
                    GreenBOS&apos;s real-time analytics and reporting ensure compliance, enhance transparency, and help organizations align with stakeholder expectations, strengthening their reputation in environmental stewardship.
                  </MKTypography>
                </MKBox>
              </Grid>
            </Grid>

            {/* Section 4 */}
            <Grid container alignItems="center" spacing={4} sx={{ my: 6, mt: {md: -6}}}>
              {/* Image Section */}
              <Grid
                item
                xs={12}
                md={6}
                order={{ xs: 1, md: 1 }} // Image comes first
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <MKBox component="img" sx={imageStyles} src={Whole4} alt="Whole4" />
              </Grid>

              {/* Text Section */}
              <Grid
                item
                xs={12}
                md={6}
                order={{ xs: 2, md: 2 }} // Text comes after the image
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <MKBox sx={{mt:{md:5}}}>
                  <MKTypography
                    variant="h3"
                    textAlign={{ xs: 'center', md: 'left' }}
                    mb={1}
                    sx={({ breakpoints, typography: { size } }) => ({
                      color: "#59535c",
                      [breakpoints.down("md")]: {
                        fontSize: size["2xl"],
                      },
                      [breakpoints.down("sm")]: {
                        fontSize: size["xl"],
                      },
                    })}
                  >
                    Benefits for Businesses
                  </MKTypography>
                  <Divider
                    sx={{
                      width: { xs: '100%', md: '90%' },
                      height: '3px',
                      backgroundColor: '#43abc1',
                    }}
                  />
                  <MKTypography
                    variant="body2"
                    color="#59535c"
                    sx={({ breakpoints }) => ({
                      fontSize: "1.2rem",
                      lineHeight: "1.5",
                      textAlign: { xs: 'center', md: 'left' },
                      mt: 3,
                      [breakpoints.down("md")]: {
                        fontSize: "1rem",
                      },
                      [breakpoints.down("sm")]: {
                        fontSize: "0.875rem",
                      },
                    })}
                  >
                    GreenBOS provides valuable insights to enhance sustainability performance by reducing energy consumption, water usage, and waste, all while minimizing costs and requiring almost no manpower in the process.
                    <br /><br />
                    It can aggregate data, allowing measurement and comparison between multiple buildings within a single site or across multiple sites.
                    This capability enables organizations to track their sustainability efforts efficiently, identify areas for improvement, and achieve their environmental goals more effectively.
                  </MKTypography>
                </MKBox>
              </Grid>
            </Grid>

          </Container>

          {/* Contact Form */}
          <Grid container alignItems="center" sx={{ mt: 6, mb: 12, marginLeft: {md: 3}}}> {/* Ensured equal vertical spacing */}
            <Grid item xs={12} md={6}>
              <MKTypography
                variant="h3"
                mt={0}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  color: "#59535c",
                  textAlign: { xs: 'center', md: 'left' }, // Center text on mobile, left on desktop
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                  [breakpoints.down("sm")]: {
                    fontSize: size["2xl"],
                  },
                })}
              >
                Contact Us
              </MKTypography>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  margin="normal"
                  multiline
                  rows={4}
                  required
                />
                <MKButton
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: '#59535c',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#59535c',
                    },
                    textTransform: 'none',
                    fontWeight: 'bold',
                    display: 'block',
                    mt: 2,
                    mx: { xs: 'auto', md: 0 },
                  }}
                >
                  SUBMIT
                </MKButton>
                {alertVisible && <Alert severity="success">Your email is on its way to SquareCloud!</Alert>}
              </form>
            </Grid>
          </Grid>

        </Container>
      </Card>
      <MKBox pt={5} px={0} mt={5}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <WhatsappChat />
    </>
  );
}

export default GreenBOS;
