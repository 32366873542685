import React, { useState } from 'react';
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Divider from '@mui/material/Divider';
import MKButton from 'components/MKButton';
import WhatsappChat from "whatsapp/WhatsappChat.js";
import TextField from '@mui/material/TextField';
import emailjs from 'emailjs-com'; // Import EmailJS
import Alert from '@mui/material/Alert';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import DefaultNavbar from "examples/Navigation/Navbar";
import DefaultFooter from "examples/Footers/Footer/footer";
import routes from "routes";
import footerRoutes from "footer.routes";
import Whole from "assets/images/CraneMain2.jpg";
import Whole2 from "assets/images/CraneMain3.jpg";
import TopPic from "assets/images/CraneMain1.jpg";
import CranePic from "assets/images/logos/removed/Crane Logo.png";

const imageStyles = {
  width: "100%",            // Ensures full width scaling
  maxWidth: "600px",         // Sets a maximum width to keep images the same size
  height: "auto",            // Maintains aspect ratio
  maxHeight: "350px",        // Ensures all images have the same maximum height
  objectFit: "cover",        // Ensures the image covers the entire container while maintaining its aspect ratio
  margin: "auto",            // Centers the image horizontally
  display: "block",          // Ensures proper centering as a block element
};

function Crane() {
  const [formData, setFormData] = useState({
    name: '',
    subject: '',
    email: '',
    message: ''
  });
  const [alertVisible, setAlertVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.name,
      subject: formData.subject,
      email: formData.email,
      message: formData.message
    };

    emailjs.send('service_m7kd5pf', 'template_8btqyep', templateParams, '1bmxxZrEdC65PCJD9')
      .then((response) => {
        console.log('Email successfully sent!', response.status, response.text);
        // Reset form data after successful submission
        setFormData({
          name: '',
          subject: '',
          email: '',
          message: ''
        });
        setAlertVisible(true);
        setTimeout(() => setAlertVisible(false), 5000);
      }, (err) => {
        console.log('Failed to send email. Error: ', err);
      });
  };

  return (
    <>
      <DefaultNavbar routes={routes} transparent light />
      
      {/* Crane Title Image */}
      <MKBox position="relative" width="100%" height="55vh" display="flex" alignItems="center" justifyContent="center">
        <img
          src={TopPic}
          alt="Top Image"
          style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "bottom" }}
        />
        <MKBox
          component="img"
          src={CranePic}
          alt="Our Specializations"
          sx={{
            position: "absolute",
            width: { xs: "70%", sm: "50%", md: "30%" },
            height: "auto",
            mt: 8,
            textAlign: "center",
            objectFit: "cover",
          }}
        />
      </MKBox>

      {/* Card for Main Content */}
      <Card
        sx={{
          p: 0,
          mx: { xs: 0, lg: 0 },
          mt: 0,
          mb: -10,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          borderRadius: 0,
        }}
      >
        <Container>
          {/* Section 1 */}
          <Grid container alignItems="center" spacing={4} sx={{ my: 6 }}>  {/* Ensured equal vertical spacing */}
            {/* Image Section */}
            <Grid
              item
              xs={12}
              md={6}
              order={{ xs: 1, md: 1 }} // Image comes first on mobile and desktop
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: { xs: 4, md: 0 }, // Add bottom margin on mobile to separate image from text
              }}
            >
              <MKBox
                component="img"
                sx={{
                  ...imageStyles,
                  height: "auto", // Ensure the image maintains aspect ratio
                  maxHeight: "100%", // Max height should match text container
                }}
                src={Whole}
                alt="Whole"
              />
            </Grid>

            {/* Text Section */}
            <Grid
              item
              xs={12}
              md={6}
              order={{ xs: 2, md: 2 }} // Text comes after the image
            >
              <MKBox
                mt={{ xs: 2, md: 0 }} // Add margin-top on mobile to create space between text and image
                mb={5}
              >
                <MKTypography
                  variant="h3"
                  textAlign={{ xs: 'center', md: 'left' }}
                  mb={1}
                  sx={({ breakpoints, typography: { size } }) => ({
                    color: "#59535c",
                    [breakpoints.down("md")]: {
                      fontSize: size["2xl"],
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: size["xl"],
                    },
                    maxWidth: '100%',
                  })}
                >
                  Comprehensive Real-Time Analysis and Network Efficiency
                </MKTypography>
                <Divider
        sx={{
          width: { xs: '100%', md: '90%' },
          height: '3px',
          backgroundColor: '#43abc1',
        }}
                />
                <MKTypography
                  variant="body2"
                  color="#59535c"
                  sx={({ breakpoints }) => ({
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    textAlign: { xs: 'center', md: 'left' },
                    mt: 3,
                    [breakpoints.down("md")]: {
                      fontSize: "1rem",
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: "0.875rem",
                    },
                    maxWidth: '100%',
                  })}
                >
                  The Crane Monitoring System offers real-time, integrated monitoring and control across industrial sites. It optimizes efficiency with advanced analytics and a centralized dashboard, providing automated reporting and instant issue detection to minimize downtime and enhance performance.
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>

          {/* Section 2 */}
          <Grid container alignItems="center" spacing={4} sx={{ my: 6 }}>  {/* Ensured equal vertical spacing */}
            {/* Image Section */}
            <Grid
              item
              xs={12}
              md={6}
              order={{ xs: 1, md: 1 }}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mb: { xs: 4, md: 0 }, // Add bottom margin on mobile
              }}
            >
              <MKBox component="img" sx={imageStyles} src={Whole2} alt="Whole2" />
            </Grid>

            {/* Text Section */}
            <Grid
              item
              xs={12}
              md={6}
              order={{ xs: 2, md: 2 }}
            >
              <MKBox
                mt={{ xs: 2, md: 0 }} // Margin-top for mobile
                mb={5}
              >
                <MKTypography
                  variant="h3"
                  textAlign={{ xs: 'center', md: 'left' }}
                  mb={1}
                  sx={({ breakpoints, typography: { size } }) => ({
                    color: "#59535c",
                    [breakpoints.down("md")]: {
                      fontSize: size["2xl"],
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: size["xl"],
                    },
                    maxWidth: '100%',
                  })}
                >
                  Powerful Features Driving Precision and Control
                </MKTypography>
                <Divider
        sx={{
          width: { xs: '100%', md: '90%' },
          height: '3px',
          backgroundColor: '#43abc1',
        }}
                />
                <MKTypography
                  variant="body2"
                  color="#59535c"
                  sx={({ breakpoints }) => ({
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    textAlign: { xs: 'center', md: 'left' },
                    mt: 3,
                    [breakpoints.down("md")]: {
                      fontSize: "1rem",
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: "0.875rem",
                    },
                    maxWidth: '100%',
                  })}
                >
                  With real-time tracking of critical devices like RHT sensors and RTDs, the Crane Monitoring System delivers customizable alerts for immediate anomaly detection. It features interactive data visualization and historical analysis, supporting predictive maintenance and scalable integration as operations expand.
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>

          {/* Contact Form */}
          <Grid container alignItems="center" sx={{ mt: 6, mb: 12, marginLeft: {md: 1}}}> {/* Ensured equal vertical spacing */}
            <Grid item xs={12} md={6}>
              <MKTypography
                variant="h3"
                mt={0}
                mb={1}
                sx={({ breakpoints, typography: { size } }) => ({
                  color: "#59535c",
                  textAlign: { xs: 'center', md: 'left' }, // Center text on mobile, left on desktop
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                  [breakpoints.down("sm")]: {
                    fontSize: size["2xl"],
                  },
                })}
              >
                Contact Us
              </MKTypography>
              <form onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  label="Name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Subject"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Email"
                  name="email"
                  type="email"
                  value={formData.email}
                  onChange={handleChange}
                  margin="normal"
                  required
                />
                <TextField
                  fullWidth
                  label="Message"
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  margin="normal"
                  multiline
                  rows={4}
                  required
                />
                <MKButton
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: '#59535c',
                    color: '#fff',
                    '&:hover': {
                      backgroundColor: '#59535c',
                    },
                    textTransform: 'none',
                    fontWeight: 'bold',
                    display: 'block',
                    mt: 2,
                    mx: { xs: 'auto', md: 0 },
                  }}
                >
                  SUBMIT
                </MKButton>
                {alertVisible && <Alert severity="success">Your email is on its way to SquareCloud!</Alert>}
              </form>
            </Grid>
          </Grid>
        </Container>
      </Card>

      <MKBox pt={5} px={0} mt={5}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <WhatsappChat />
    </>
  );
}

export default Crane;
