import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import MKInput from 'components/MKInput';
import { Grid, Card } from '@mui/material';
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import MKButton from 'components/MKButton';
import PropTypes from 'prop-types';
import DefaultNavbar from "examples/Navigation/Navbar"
import DefaultFooter from "examples/Footers/DefaultFooter";
import Select from 'react-dropdown-select';
import routes from "routes";
import footerRoutes from "footer.routes";
import { collection, addDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db } from 'firebase.js';

const storage = getStorage();

const AddInfo = () => {
    const [formData, setFormData] = useState({
        files: null,
        internetConnection: '',
        additionalInfo: '',
        bmsInstall: '', bmsMake: '', interface: '', remarks: ''
    });

    const handleFileChange = (e) => {
        const { name, files } = e.target;
        setFormData({ ...formData, [name]: files[0] });
    };

    const handleDropdownChange = (value, name) => {
        setFormData({ ...formData, [name]: value[0].value });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate();


    const uploadFile = async (file) => {
        const storageRef = ref(storage, `files/${file.name}`);
        await uploadBytes(storageRef, file);
        return await getDownloadURL(storageRef);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (currentStep === 2) {
            try {
                const uploadedFiles = {};
                for (const [key, value] of Object.entries(formData)) {
                    if (value instanceof File) {
                        uploadedFiles[key] = await uploadFile(value);
                    } else {
                        uploadedFiles[key] = value;
                    }
                }
                navigate('/pages/landing-pages/get-data');
                await addDoc(collection(db, 'AddInfo'), uploadedFiles);
                alert('Data saved successfully!');
            } catch (e) {
                console.error('Error adding document: ', e);
            }
        } else {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrevious = () => {
        if (currentStep > 1) {
            setCurrentStep(currentStep - 1);
        }
    };

    return (
        <>
            <DefaultNavbar routes={routes} transparent light />
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: 0,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
                    <Grid container spacing={1} alignItems="center" height="100%" justifyContent="center">
                        <Grid item xs={11} sm={9} md={7} lg={6} xl={5}>
                            <MKBox
                                variant="gradient"
                                bgColor="info"
                                borderRadius="lg"
                                coloredShadow="info"
                                mx={2}
                                mt={-3}
                                p={2}
                                mb={1}
                                textAlign="center"
                            >
                                <MKTypography variant="h4" fontWeight="medium" color="white" textAlign="center" mt={1}>
                                    Additional Information
                                </MKTypography>
                            </MKBox>
                            <MKBox pt={4} pb={4} px={4}>
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={2}>
                                        {currentStep === 1 && (
                                            <>
                                                <Grid item xs={12}>
                                                    <MKBox mb={2}>
                                                        <MKTypography color="textPrimary" variant="body1">Additional Information compiled with .zip or .rar</MKTypography>
                                                        <MKInput type="file" name="files" fullWidth onChange={handleFileChange} />
                                                    </MKBox>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <MKBox mb={2}>
                                                        <MKTypography color="textPrimary" variant="body1">Internet connection</MKTypography>
                                                        <Select
                                                            options={[
                                                                { value: 'yes', label: 'Yes' },
                                                                { value: 'no', label: 'No' }
                                                            ]}
                                                            onChange={(value) => handleDropdownChange(value, 'internetConnection')}
                                                            values={[{ value: formData.internetConnection, label: formData.internetConnection }]}
                                                            multi={false}
                                                        />
                                                    </MKBox>
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <MKBox mb={2}>
                                                        <MKTypography color="textPrimary" variant="body1">Remarks (Additional)</MKTypography>
                                                        <MKInput sx={{ input: { color: 'textPrimary' } }} variant="outlined" name="additionalInfo" fullWidth onChange={handleInputChange} />
                                                    </MKBox>
                                                </Grid>
                                            </>)}
                                        {currentStep === 2 && (
                                            <>

                                                <Grid item xs={12}>
                                                    <MKTypography variant="body1">BMS/BAS/EMS/CPMS installed?</MKTypography>
                                                    <MKBox mb={2}>
                                                        <Select
                                                            options={[
                                                                { value: 'yes', label: 'Yes' },
                                                                { value: 'no', label: 'No' }
                                                            ]}
                                                            onChange={(value) => handleDropdownChange(value, 'bmsInstall')}
                                                            values={[{ value: formData.bmsInstall, label: formData.bmsInstall }]}
                                                            multi={false}
                                                        />
                                                    </MKBox>
                                                    <MKBox mb={2}>
                                                        <MKInput
                                                            variant="outlined"
                                                            label="BMS/BAS/EMS/CPMS Installed Make"
                                                            fullWidth
                                                            name="bmsMake"
                                                            value={formData.bmsMake}
                                                            onChange={handleInputChange}
                                                        />
                                                    </MKBox>
                                                    <MKTypography variant="body1">Interfaced to the chiller via:</MKTypography>
                                                    <MKBox mb={2}>
                                                        <Select
                                                            options={[
                                                                { value: 'High', label: 'High Level Interface' },
                                                                { value: 'Low', label: 'Low Interface Level' }
                                                            ]}
                                                            onChange={(value) => handleDropdownChange(value, 'interface')}
                                                            values={[{ value: formData.interface, label: formData.interface }]}
                                                            multi={false}
                                                        />
                                                    </MKBox>
                                                    <MKBox mb={2}>
                                                        <MKInput
                                                            variant="outlined"
                                                            label="Remarks (Features)"
                                                            fullWidth
                                                            name="remarks"
                                                            value={formData.remarks}
                                                            onChange={handleInputChange}
                                                        />
                                                    </MKBox>
                                                </Grid>                                    </>)}

                                        <Grid item xs={12}>
                                            <MKButton mt={4} ml={2} mb={1} type="submit" variant="gradient" color="dark">
                                                {currentStep === 2 ? 'Submit' : 'Next'}
                                            </MKButton>
                                            <MKButton mt={4} mr={2} mb={1} type="button" variant="outlined" color="dark" onClick={handlePrevious}>
                                                Previous
                                            </MKButton>
                                        </Grid>
                                    </Grid>
                                </form>
                            </MKBox>

                        </Grid>
                    </Grid>
                </MKBox>
            </Card>
            <MKBox pt={6} px={0} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
        </>
    );
};

AddInfo.propTypes = {
    electricityBill: PropTypes.object,
    floorPlan: PropTypes.object,
    electricalDiagram: PropTypes.object,
    waterPiping: PropTypes.object,
    ahuList: PropTypes.object,
    panelLocation: PropTypes.string,
    internetConnection: PropTypes.string,
    additionalInfo: PropTypes.string,
};

export default AddInfo;