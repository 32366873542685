import React from 'react';
import 'examples/Footers/Footer/footer.css'; // Ensure this path matches your project's structure
import logo from 'assets/images/Pics _Chiller/removed.png';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Logo and Description */}
        <div className="footer-column logo-column">
          <img src={logo} alt="Square Cloud Logo" className="footer-logo" />
        </div>

        {/* Navigation Columns */}
        <div className="footer-column">
          <h4>About Us</h4>
          <ul>
            <li><a href="/VisionMission">Our Mission</a></li>
            <li><a href="/AboutUS">About Us</a></li>
            <li><a href="/ourPartner">Partners</a></li>
            {/* <li><a href="/presentation#testimonials">Testimonials</a></li>*/}
          </ul>
        </div>
        <div className="footer-column">
          <h4>Services</h4>
          <ul>
            <li><a href="/Services">Energy Management</a></li>
            <li><a href="/analytics">Solutions</a></li>
            <li><a href="/iotPage">IoT Solution</a></li>
            <li><a href="/Monitoring">Monitoring</a></li>
          </ul>
        </div>
        <div className="footer-column">
          <h4>Contact</h4>
          <ul>
            <li><a href="/contactUs">Contact Us</a></li>
            <li><a href="/contactUs">Locations</a></li>
            <li><a href="/contactUs">FAQ</a></li>
            {/* Footer Bottom <li><a href="/contactUs">Newsletter</a></li>*/}
          </ul>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="footer-bottom">
        <p>
            Square Cloud provides cutting-edge solutions for energy management and optimization. Our mission is to enhance efficiency through innovation.
          </p>
        <p><b>&copy; 2024 Square Cloud. All rights reserved.</b></p>
      </div>
    </footer>
  );
};

export default Footer;
