class ActionProvider {
    constructor(createChatBotMessage, setStateFunc) {
        this.createChatBotMessage = createChatBotMessage;
        this.setState = setStateFunc;
    }

    handleJsList =()=>{
        const message = this.createChatBotMessage(
            "Wonderful, I have the following materials available for you.",
        {
            widget: "jsxLink",
        }
    );
    this.updateChatbotState(message);
    };
    handleAnalytics =()=>{
        const message = this.createChatBotMessage(
            "Wonderful, I have the following materials available for you.",
        {
            widget: "analyticsLink",
        }
    );
    this.updateChatbotState(message);
    };
    handleMonitoring =()=>{
        const message = this.createChatBotMessage(
            "Wonderful, I have the following materials available for you.",
        {
            widget: "monitorLink",
        }
    );
    this.updateChatbotState(message);
    };
    handleIoTArchitecture =()=>{
        const message = this.createChatBotMessage(
            "Wonderful, I have the following materials available for you.",
        {
            widget: "iotLink",
        }
    );
    this.updateChatbotState(message);
    }; 
    handleContactUs =()=>{
        const message = this.createChatBotMessage(
            "Wonderful, I have the following materials available for you.",
        {
            widget: "contactLink",
        }
    );
    this.updateChatbotState(message);
    };
    handleAboutUs =()=>{
        const message = this.createChatBotMessage(
            "Wonderful, I have the following materials available for you.",
        {
            widget: "aboutLink",
        }
    );
    this.updateChatbotState(message);
    };
    handleHello() {
        const message = this.createChatBotMessage("Hello. Nice to meet you.");
       this.updateChatbotState(message)
    }
    updateChatbotState(message){
        this.setState(prevState => ({
            ...prevState,messages:[...prevState.messages,message]
        }))
    }

}

export default ActionProvider;
{/*
handleSaving =()=>{
    const message = this.createChatBotMessage(
        "Wonderful, I have the following materials available for you.",
    {
        widget: "savingLink",
    }
);
this.updateChatbotState(message);
};*/}