import React, { useState } from 'react';
import Grid from "@mui/material/Grid";
import MKTypography from 'components/MKTypography';
import Alert from '@mui/material/Alert';
import MKButton from 'components/MKButton';
import emailjs from 'emailjs-com';
import TextField from '@mui/material/TextField';
import { Box } from "@mui/system";

// images
import contactImage from "assets/images/background.png";

function Counters() {
  const [formData, setFormData] = useState({
    name: '',
    subject: '',
    email: '',
    message: ''
  });
  const [alertVisible, setAlertVisible] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateParams = {
      from_name: formData.name,
      subject: formData.subject,
      email: formData.email,
      message: formData.message
    };

    emailjs.send('service_m7kd5pf', 'template_8btqyep', templateParams, '1bmxxZrEdC65PCJD9')
      .then((response) => {
        console.log('Email successfully sent!', response.status, response.text);
        setFormData({
          name: '',
          subject: '',
          email: '',
          message: ''
        });
        setAlertVisible(true);
        setTimeout(() => setAlertVisible(false), 5000);
      }, (err) => {
        console.log('Failed to send email. Error: ', err);
      });
  };

  return (
    <Box sx={{ maxWidth: {md: "1275px"}, height: {md: "500px"}, margin: "0 auto", // Centers the component horizontally

    alignContent: { md: "center" },
    width: '100%', 
     }}>
      <Grid container sx={{ height: '100%', mx: 'auto', boxShadow: 1 }}>
        <Grid item xs={12} md={6}  sx={{ display: 'flex', alignItems: 'center' }}>
          <Box
            component="img"
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'cover',
            }}
            src={contactImage}
            alt="Contact"
          />
        </Grid>
        <Grid item xs={12} md={6} sx={{ px: { xs: 2, md: 5 },  width: '100%', height:'100%', py: 4, backgroundColor: "#004c77" , opacity:"75%"}}>
          <MKTypography variant="h5" sx={{ color: "#ffffff", mb: 2 }}>
            Looking for a solution? We&apos;d love to hear who we can help.
          </MKTypography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              margin="normal"
              required
              InputLabelProps={{
                style: { color: '#ffffff' },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  color: '#ffffff',
                },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ffffff',
                },
                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ffffff',
                },
              }}
            />
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              required
              InputLabelProps={{
                style: { color: '#ffffff' },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  color: '#ffffff',
                },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ffffff',
                },
                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ffffff',
                },
              }}
            />
            <TextField
              fullWidth
              label="Message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              margin="normal"
              multiline
              rows={4}
              required
              InputLabelProps={{
                style: { color: '#ffffff' },
              }}
              sx={{
                '& .MuiInputBase-root': {
                  color: '#ffffff',
                },
                '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ffffff',
                },
                '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                  borderColor: '#ffffff',
                },
              }}
            />
            <MKButton
              type="submit"
              variant="contained"
              sx={{
                backgroundColor: '#59535c',
                color: '#fff',
                '&:hover': {
                  backgroundColor: '#59535c',
                },
                textTransform: 'none',
              }}
            >
              SUBMIT
            </MKButton>              
            {alertVisible && <Alert severity="success" sx={{ mt: 2 }}>There you go! Your email is on its way to SquareCloud</Alert>}
          </form>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Counters;
