import React, { useEffect, useState } from 'react';
import 'examples/Navigation/Navbar.css';
import logo from 'assets/images/Pics _Chiller/removed.png';
import { Link } from 'react-router-dom';

function Navbar() {
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [activeMenuIndex, setActiveMenuIndex] = useState(null); // State to track which menu is open

  // List of pages or sections to search through
  const pages = [
    { id: 1, names: ["Analytics", "Analysis", "analyse", "reports", "reporting"], path: "/Maximizee" },
    { id: 2, names: ["Monitoring", "monitor", "crane"], path: "/monitoring" },
    { id: 3, names: ["IoT Solutions", "iot", "internet of things", "sensors"], path: "/iotPage" },
    { id: 4, names: ["Contact Us", "Location", "Support", "Map"], path: "/contactUs" },
    { id: 5, names: ["About Us", "Company Info"], path: "/AboutUs" },
    { id: 6, names: ["Vision and Mission", "Our vision", "Mission "], path: "/VisionMission" },
    { id: 7, names: ["Our Partners", "Mimos", "Skada", "TNB"], path: "/ourPartner" },
    { id: 8, names: ["Services", "Our Service", "Products"], path: "/Services" },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      setVisible((prevScrollPos > currentScrollPos && prevScrollPos - currentScrollPos > 70) || currentScrollPos < 10);
      setPrevScrollPos(currentScrollPos);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [prevScrollPos]);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
    setActiveMenuIndex(null); // Reset the active menu index
  };

  const toggleSubMenu = (index) => {
    setActiveMenuIndex(activeMenuIndex === index ? null : index); // Toggle the active menu index
  };

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
    setSearchResults([]); // Clear search results when closing
  };

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      const filtered = pages.filter((page) =>
        page.names.some(name => name.toLowerCase().includes(searchQuery.toLowerCase()))
      );
      setSearchResults(filtered);
    } else {
      setSearchResults([]);
    }
  };

  return (
    <nav className={visible ? 'navbar' : 'navbar hidden'}>
      <div className="navbar-container">
        <a href="/" className="navbar-logo">
          <img src={logo} alt="SquareCloud Logo" />
        </a>
        <button className={`hamburger ${menuOpen ? 'active' : ''}`} onClick={toggleMenu}>
          <div />
          <div />
          <div />
        </button>
        <ol className={`nav-menu ${menuOpen ? 'active' : ''}`}>
          <button className="close-btn" onClick={closeMenu}>&times;</button>
          <li className="nav-item">
            <div className="nav-links" onClick={() => toggleSubMenu(0)}>Who We Are
              <span className="arrow-down">&#x25BC;</span> {/* Arrow icon */}
            </div>
            <ul className={`dropdown-menu ${activeMenuIndex === 0 ? 'active' : ''}`}>
              <li><a href="/VisionMission" className="dropdown-item" onClick={closeMenu}>Vision and Mission</a></li>
              <li><a href="/OurCoreValues" className="dropdown-item" onClick={closeMenu}>Our Core Values</a></li>
              <li><a href="/AboutUs" className="dropdown-item" onClick={closeMenu}>About Us</a></li>
              <li><a href="/ourPartner" className="dropdown-item" onClick={closeMenu}>Our Partners</a></li>
            </ul>
          </li>
          <li className="nav-item">
            <div className="nav-links" onClick={() => toggleSubMenu(1)}>What We Do
              <span className="arrow-down">&#x25BC;</span> {/* Arrow icon */}
            </div>
            <ul className={`dropdown-menu ${activeMenuIndex === 1 ? 'active' : ''}`}>
              <li><a href="/Services" className="dropdown-item" onClick={closeMenu}>Energy Management & Efficiency Services</a></li>
              <li><a href="/iotPage" className="dropdown-item" onClick={closeMenu}>Our AI and IOT Solutions</a></li>
              <li><a href="/Monitoring" className="dropdown-item" onClick={closeMenu}>RealTime Monitoring</a></li>
            </ul>
          </li>
          <li className="nav-item">
            <div className="nav-links" onClick={() => toggleSubMenu(0)}>What We Specialize
              <span className="arrow-down">&#x25BC;</span> {/* Arrow icon */}</div>
            <ul className={`dropdown-menu ${activeMenuIndex === 0 ? 'active' : ''}`}>
              <li><a href="/Maximizee" className="dropdown-item" onClick={closeMenu}>MaximizEE</a></li>
              <li><a href="/Intellisenz" className="dropdown-item" onClick={closeMenu}>Intellisenz</a></li>
              <li><a href="/Crane" className="dropdown-item" onClick={closeMenu}>CranE</a></li>
              <li><a href="/GreenBOS" className="dropdown-item" onClick={closeMenu}>GreenBOS</a></li>
            </ul>
          </li>
          <li className="nav-item">
            <a href="/contactUs" className="nav-links" onClick={closeMenu}>Contact Us</a>
          </li>
          <div className={`search-container ${searchOpen ? 'active' : ''}`}>
            <button className="search-button" onClick={toggleSearch}>
              {searchOpen ? <i className="fa fa-times" aria-hidden="true"></i> : <i className="fa fa-search" aria-hidden="true"></i>}
            </button>
            {searchOpen && (
              <form onSubmit={handleSearch}>
                <input
                  type="text"
                  className="search-input"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </form>
            )}
            {searchOpen && searchResults.length > 0 && (
              <ul className="search-results">
                {searchResults.map((result) => (
                  <li key={result.id}>
                    <Link to={result.path} onClick={toggleSearch}>{result.names[0]}</Link>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </ol>
      </div>
    </nav>
  );
}

export default Navbar;
