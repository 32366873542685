// @mui material components
import Card from "@mui/material/Card";

//import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import WhatsappChat from "whatsapp/WhatsappChat.js";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navigation/Navbar"
import DefaultFooter from "examples/Footers/Footer/footer"

//sections
import ContactForm from "pages/LandingPages/ContactUs/sections/ContactForm";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import TopPic from "assets/images/partners/servicesBack.jpg";


function ContactUs() {
  return (
    <>
      <DefaultNavbar
        routes={routes}
        transparent
        light
      />
      <MKBox position="relative" width="100%" height="55vh" display="flex" alignItems="center" justifyContent="center">
        <img
          src={TopPic}
          alt="Top Image"
          style={{ width: "100%", height: "100%", objectFit: "cover", objectPosition: "" }}
        />
        <MKTypography
          variant="h1"
          component="div"
          color="white"
          sx={{
            position: "absolute",
            fontSize: { xs: "3rem", sm: "4rem", md: "5rem" },
            fontWeight: "bold",
            mt: 8,
            textAlign: "center",
            textShadow: "2px 2px 4px rgba(0,0,0,0.6)",
          }}
        >
          Contact Us
        </MKTypography>
      </MKBox>
      <Card
        sx={{
          p: 0,
          mx: { xs: 0, lg: 0 },
          mt: 0,
          mb: 0,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          borderRadius: 0
        }}
      >
        <Grid item xs={12} md={8} mt={2}>
          <ContactForm />
        </Grid>
      </Card>
      <MKBox pt={0} px={0} mt={0}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
      <WhatsappChat />
    </>
  );
}

export default ContactUs;
