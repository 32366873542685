import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Grid, Card } from '@mui/material';
import MKBox from 'components/MKBox';
import MKTypography from 'components/MKTypography';
import MKInput from 'components/MKInput';
import MKButton from 'components/MKButton';
import Select from 'react-dropdown-select';
// Material Kit 2 React example components
import DefaultNavbar from "examples/Navigation/Navbar"
import DefaultFooter from "examples/Footers/DefaultFooter";
//routes react
import routes from "routes";
import footerRoutes from "footer.routes";
//firebase db
import { db } from 'firebase.js';
import { collection, addDoc } from 'firebase/firestore';

function SavingsForm() {
    const [formData, setFormData] = useState({
        cname: '', nameinc: '', designation: '', emailinc: '',
        buildYear: '', nobuilding: '', nofloor: '', estarea: '', startTime: '', finishTime: '', workdays: '',
        publicwork: '', hotelGuest: '', hall: '', rooms: '',
        patient: '', roomp: '', operation: '', offBuild: '', indiMeter: '',
        factoryCool: '', cleanroom: '', roomclass: '', cleanreq: '', cleanhumid: '',
        yesnoOption: [{ value: 'yes', label: 'yes' }, { value: 'no', label: 'no' }],
        buildTypeOptions: [
            { value: 'hospital', label: 'Hospital' },
            { value: 'hotel', label: 'Hotel' },
            { value: 'mall', label: 'Mall' },
            { value: 'office', label: 'Office' },
            { value: 'factory', label: 'Factory' },
        ],
        tariffOptions: [
            { value: 'B', label: 'B (Low Voltage Commercial)', details: { cost: [{ range: "1-200kwh", rate: 43.5 }, { range: "201kwh>", rate: 50.9 }], minCharge: 7.20 } },
            { value: 'C1', label: 'C1 (Medium Voltage Commercial)', details: { cost: [{ range: "all", rate: 36.5 }], minCharge: 600 } },
            { value: 'C2', label: 'C2 (Medium Peak/Off Peak Commercial)', details: { cost: [{ type: "peak", rate: 36.5 }, { type: "off-peak", rate: 22.4 }], minCharge: 600 } },
            { value: 'D', label: 'D (Low Voltage Industrial)', details: { cost: [{ range: "1-200kwh", rate: 38.0 }, { range: "201kwh>", rate: 44.1 }] } },
            { value: 'E1', label: 'E1 (Medium Voltage Industrial)', details: { cost: [{ type: "demand", rate: 29.6 }, { type: "energy", rate: 33.7 }], minCharge: 600 } },
            { value: 'E2', label: 'E2 (Medium Voltage Peak/Off Peak Industrial)', details: { cost: [{ type: "demand", rate: 37.0 }, { type: "peak", rate: 35.5 }, { type: "off-peak", rate: 21.9 }], minCharge: 600 } },
            { value: 'E3', label: 'E3 (High Voltage Peak/Off Peak Industrial)', details: { cost: [{ type: "demand", rate: 35.5 }, { type: "peak", rate: 33.7 }, { type: "off-peak", rate: 20.2 }], minCharge: 600 } },
            { value: 'N2', label: 'N2 (Low Voltage Commercial)', details: { cost: [{ range: "0-200kwh", rate: 45.0 }, { range: "201kwh>", rate: 51.0 }] } },
            { value: 'N3', label: 'N3 (High Voltage Commercial)', details: { cost: [{ type: "demand", rate: 32.27 }, { type: "energy", rate: 36.0 }] } },
            { value: 'N4', label: 'N4 (Low Voltage Industrial)', details: { cost: [{ range: "0-200kwh", rate: 40.0 }, { range: "201kwh>", rate: 45.0 }] } },
            { value: 'N5', label: 'N5 (High Voltage Industrial)', details: { cost: [{ type: "demand", rate: 30.26 }, { type: "energy", rate: 33.7 }] } },
            { value: 'N6', label: 'N6 (High Voltage (33/11Kv) Peak/Off Peak Industrial)', details: { cost: [{ type: "demand", rate: 40.0 }, { type: "peak", rate: 34.95 }, { type: "off-peak", rate: 22.0 }] } },
            { value: 'N8', label: 'N8 (High Voltage (132Kv) Peak/Off Peak Industrial)', details: { cost: [{ type: "demand", rate: 38.38 }, { type: "peak", rate: 33.18 }, { type: "off-peak", rate: 20.29 }] } }
        ],
    });
    const [currentStep, setCurrentStep] = useState(1);
    const navigate = useNavigate();
    const handleInput = (event) => {
        const { name, value } = event.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };
    const handleSelectChange = (selected, name) => {
        const value = selected[0].value; // Assuming single select
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const { buildType } = formData;
        const stepsToSkip = {
            hospital: [4, 6, 7],
            hotel: [5, 6, 7],
            mall: [4, 5, 6, 7],
            office: [4, 5, 7],
            factory: [4, 5, 6],
        };

        if (currentStep === 8 || (stepsToSkip[buildType] && stepsToSkip[buildType].includes(currentStep + 1))) {
            console.log('Form data:', formData);
            try {
                await addDoc(collection(db, 'SavingsForm'), formData);
                alert('Form submitted successfully!');
                localStorage.setItem('formData', JSON.stringify(formData));
                navigate('/pages/landing-pages/chillerform');
            } catch (error) {
                console.error('Error adding document: ', error);
                alert('Error submitting form. Please try again.');
            }
        } else {
            let nextStep = currentStep + 1;
            while (stepsToSkip[buildType] && stepsToSkip[buildType].includes(nextStep)) {
                nextStep++;
            }
            setCurrentStep(nextStep);
        }
    };

    const handlePrevious = () => {
        const { buildType } = formData;
        const stepsToSkip = {
            hospital: [4, 6, 7],
            hotel: [5, 6, 7],
            mall: [4, 5, 6, 7],
            office: [4, 5, 7],
            factory: [4, 5, 6],
        };
        let prevStep = currentStep - 1;
        while (stepsToSkip[buildType] && stepsToSkip[buildType].includes(prevStep)) {
            prevStep--;
        }
        if (prevStep > 0) {
            setCurrentStep(prevStep);
        }
    };
    return (
        <>
            <DefaultNavbar
                routes={routes}
                transparent
                light
            />
            <Card
                sx={{
                    p: 2,
                    mx: { xs: 2, lg: 3 },
                    mt: 0,
                    mb: 4,
                    boxShadow: ({ boxShadows: { xxl } }) => xxl,
                }}
            >
                <MKBox px={1} width="100%" height="100vh" mx="auto" position="relative" zIndex={2}>
                    <Grid container spacing={1} alignItems="center" height="100%" justifyContent="center">
                    <Grid item xs={11} sm={9} md={7} lg={6} xl={5}>
                    <MKBox variant="gradient"
                                    bgColor="info"
                                    borderRadius="lg"
                                    coloredShadow="info"
                                    mx={2}
                                    mt={-3}
                                    p={2}
                                    mb={1}
                                    textAlign="center">
                                    <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
                                        {currentStep < 3 ? 'General Information' : currentStep < 4 ? 'Building Details'
                                            : currentStep < 8 ? 'Building Operation' : `Form ${currentStep} of 8`}
                                    </MKTypography>
                                </MKBox>
                                <MKBox pt={4} pb={4} px={4}>
                                    <form onSubmit={handleSubmit}>
                                        {currentStep === 1 && (
                                            <>
                                                <MKBox mb={2}>
                                                    <MKInput variant="outlined" label="Company Name" fullWidth name="cname" value={formData.cname} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput variant="outlined" label="Person in Charge Name" fullWidth name="nameinc" value={formData.nameinc} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput variant="outlined" label="Designation" fullWidth name="designation" value={formData.designation} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput type="email" label="Email" fullWidth name="emailinc" value={formData.emailinc} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKTypography variant="body1"> Building Type</MKTypography>
                                                    <Select
                                                        options={formData.buildTypeOptions}
                                                        onChange={(value) => handleSelectChange(value, 'buildType')}
                                                        values={formData.buildType ? [{ value: formData.buildType, label: formData.buildType }] : []}
                                                    />
                                                </MKBox>
                                            </>
                                        )}
                                        {currentStep === 2 && (
                                            <>
                                                <MKBox mb={2}>
                                                    <MKInput type="number" label="Building Year" fullWidth name="buildYear" value={formData.buildYear} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput type="number" label="Number of Building" fullWidth name="nobuilding" value={formData.nobuilding} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput type="number" label="Number of floors" fullWidth name="nofloor" value={formData.nofloor} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput type="number" label="Estimate floor area m/2" fullWidth name="estarea" value={formData.estarea} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKTypography variant="body1" color="textPrimary" mb={1}>Tariff Type</MKTypography>
                                                    <Select
                                                        options={formData.tariffOptions.map(option => ({ value: option.value, label: option.label }))}
                                                        onChange={handleSelectChange}
                                                        value={formData.tariffOption ? { value: formData.tariffOption.value, label: formData.tariffOption.label } : null}
                                                    />
                                                </MKBox>
                                            </>
                                        )}
                                        {currentStep === 3 && (
                                            <>
                                                <MKBox mb={2}>
                                                    <MKInput type="time" label="General Start Time (Work Hours)" fullWidth name="startTime" value={formData.startTime} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput type="time" label="General Finish Time (Work Hours)" fullWidth name="finishTime" value={formData.finishTime} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput type="number" label="Number Work Days" fullWidth name="workdays" value={formData.workdays} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <p>Work on Public Holidays?</p>
                                                    <Select
                                                        options={formData.yesnoOption}
                                                        onChange={(value) => handleSelectChange(value, 'publicwork')}
                                                        values={formData.publicwork ? [{ value: formData.publicwork, label: formData.publicwork }] : []}
                                                    />
                                                </MKBox>
                                            </>
                                        )}

                                        {currentStep === 4 && formData.buildType === 'hotel' && (
                                            <>
                                                <p>For Hotel</p>
                                                <MKBox mb={2}>
                                                    <MKInput variant="outlined" label="Number of Guest Room" fullWidth name="hotelGuest" value={formData.hotelGuest} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput variant="outlined" label="Number of Banquet Halls" fullWidth name="hall" value={formData.hall} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput type="number" label="Number of Function Rooms" fullWidth name="rooms" value={formData.rooms} onChange={handleInput} />
                                                </MKBox>
                                            </>
                                        )}
                                        {currentStep === 5 && formData.buildType === 'hospital' && (
                                            <>
                                                <p>For Hospital</p>
                                                <MKBox mb={2}>
                                                    <MKInput variant="outlined" label="Number of Patient\'s Beds" fullWidth name="patient" value={formData.patient} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput variant="outlined" label="Number of Patient\'s Rooms" fullWidth name="roomp" value={formData.roomp} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput type="number" label="Number of Operation Theatres" fullWidth name="operation" value={formData.operation} onChange={handleInput} />
                                                </MKBox>
                                            </>
                                        )}
                                        {currentStep === 6 && (formData.buildType === 'office' || formData.buildType === 'mall') && (
                                            <>
                                                <p>For Office</p>
                                                <MKBox mb={2}>
                                                    <MKInput variant="outlined" label="Number of Staffs" fullWidth name="offBuild" value={formData.offBuild} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput variant="outlined" label="Number of tenants have individual kwH meters" fullWidth name="indiMeter" value={formData.indiMeter} onChange={handleInput} />
                                                </MKBox>
                                            </>
                                        )}

                                        {currentStep === 7 && formData.buildType === 'factory' && (
                                            <>
                                                <p>For Factory</p>
                                                <MKBox mb={2}>
                                                    <MKInput variant="outlined" label="Number of Process Cooling" fullWidth name="factoryCool" value={formData.factoryCool} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput type="number" label="Number of Clean Rooms" fullWidth name="cleanroom" value={formData.cleanroom} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput type="number" label="Clean Room Class" fullWidth name="roomclass" value={formData.roomclass} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput type="number" label="Clean Room Temperature Requirement" fullWidth name="cleanreq" value={formData.cleanreq} onChange={handleInput} />
                                                </MKBox>
                                                <MKBox mb={2}>
                                                    <MKInput type="number" label="Clean Room Humidity Requirement" fullWidth name="cleanhumid" value={formData.cleanhumid} onChange={handleInput} />
                                                </MKBox>
                                            </>
                                        )}
                                        <MKButton mt={4} mr={2} ml={1} mb={1} type="button" variant="outlined" color="dark" onClick={handlePrevious}>
                                            Previous
                                        </MKButton>
                                        <MKButton mt={4} mr={1} ml={2} mb={1} type="submit" variant="gradient" color="dark">
                                            {currentStep === 6 ? 'Proceed to Chiller Form' : 'Next'}
                                        </MKButton>
                                    </form>
                                </MKBox>
                        </Grid>
                    </Grid>
                </MKBox>
            </Card>
            <MKBox pt={6} px={0} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>

        </>
    );
}

export default SavingsForm;
