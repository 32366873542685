import React from 'react';
import { Grid, Container } from '@mui/material';
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Partner Images
import TNBImage from 'assets/images/partners/tnbes.jpg';
import TNB from 'assets/images/partners/tnb.jpeg';
import isnet from 'assets/images/partners/spka.png';
import Mimos from 'assets/images/partners/mimos.jpg';
import DNZ from 'assets/images/partners/dnz.png';
import Axicom from 'assets/images/partners/axicom.jpg';

const imageStyles = {
  width: "100%",         // Ensures full width scaling
  maxWidth: "350px",     // Adjusts for max image width
  height: "300px",       // Limits height for uniformity
  objectFit: "contain",  // Ensures the image covers the entire container without distortion
  margin: "auto",        // Centers the image horizontally
  display: "block",      // Ensures proper centering
  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",  // Adds a subtle box shadow
};

const partners = [
  {
    name: "Mimos, Kuala Lumpur, Malaysia",
    description:
      "Our Technology Partner since 2020. We collaborate with Mimos and license the Technologies to implement in Commercial and Industrial Buildings. Research and development centre specialist in semiconductor, advanced technology and ICT.",
    imageSrc: Mimos,
    alt: "Mimos",
    link: "https://www.mimos.my/main/",
    linkText: "mimos.my",
  },
  {
    name: "TNB Energy Services",
    description:
      "Offering innovative energy solutions such as energy efficiency consulting, renewable energy, and energy management systems. They focus on helping clients reduce energy consumption and costs while minimizing environmental impact, supporting Malaysia's transition to sustainable energy practices.",
    imageSrc: TNBImage,
    alt: "TNB Energy Services",
    link: "https://www.tnbes.com.my",
    linkText: "www.tnbes.com.my",
  },
  {
    name: "Tenaga Nasional Berhad",
    description:
      "Malaysia's largest electricity utility company, providing power generation, transmission, and distribution services nationwide. TNB is dedicated to sustainable energy solutions, focusing on renewable energy and energy efficiency to drive economic growth and environmental goals.",
    imageSrc: TNB,
    alt: "TNB",
    link: "https://www.tnb.com.my/",
    linkText: "www.tnb.com.my",
  },
  {
    name: "DNZ.ASIA, Singapore",
    description:
      "IT solutions provider specializing in data center services, cloud solutions, and IT infrastructure. With a focus on innovation and reliability, DNZ Asia delivers customized solutions that enhance business performance and support digital transformation across various industries.",
    imageSrc: DNZ,
    alt: "DNZ.Asia",
    link: "https://www.dnz.asia/",
    linkText: "dnz.asia",
  },
  {
    name: "iScada Net, Selangor, Malaysia",
    description:
      "Specializes in industrial automation and monitoring solutions, offering advanced SCADA (Supervisory Control and Data Acquisition) systems. They provide real-time data analytics and remote monitoring for various industries, enhancing operational efficiency, productivity, and decision-making through innovative technology solutions.",
    imageSrc: isnet,
    alt: "iScada Net",
    link: "https://isnet.my/",
    linkText: "isnet.my",
  },
  {
    name: "AxiCom, Selangor, Malaysia",
    description:
      "Specializing in building automation and energy management solutions. They offer a range of services, including system integration, maintenance, and consultancy, to enhance building efficiency and sustainability, leveraging cutting-edge technology for smarter, greener operations.",
    imageSrc: Axicom,
    alt: "AxiCom",
    link: "https://www.axicom.my/",
    linkText: "axicom.my",
  },
];

const PartnerContent = () => {
  return (
    <MKBox component="section" py={8}>
      <Container
        sx={{
          maxWidth: "84%",      // Set container width to 80% of the screen
          paddingLeft: "8%",   // Ensure 10% padding on both left and right sides
          paddingRight: "8%",
          margin: "0 auto",     // Center the container by setting margin to auto
          marginTop: "-120px"
        }}
      >
        {/* Map through each partner to create a vertically aligned section */}
        {partners.map((partner, index) => (
          <Grid container alignItems="center" justifyContent="center" spacing={9} sx={{ my: 6 }} key={index}>
            {/* Image Section */}
            <Grid item xs={12} md={6}>
              <MKBox component="img" src={partner.imageSrc} alt={partner.alt} sx={imageStyles} />
            </Grid>

            {/* Text Section */}
            <Grid item xs={12} md={6}>
              <MKBox>
                <MKTypography
                  variant="h3"
                  mb={1}
                  textAlign={{ xs: 'center', md: 'left' }}
                  sx={({ breakpoints, typography: { size } }) => ({
                    color: "#59535c",
                    [breakpoints.down("md")]: {
                      fontSize: size["2xl"],
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: size["xl"],
                    },
                  })}
                >
                  {partner.name}
                </MKTypography>
                <MKTypography
                  variant="body2"
                  color="#59535c"
                  sx={({ breakpoints }) => ({
                    fontSize: "1.2rem",
                    lineHeight: "1.5",
                    textAlign: { xs: 'center', md: 'left' },
                    [breakpoints.down("md")]: {
                      fontSize: "1rem",
                    },
                    [breakpoints.down("sm")]: {
                      fontSize: "0.875rem",
                    },
                  })}
                >
                  {partner.description}
                  <br />
                  <a
                    href={partner.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'none',
                      color: 'inherit',
                      fontStyle: 'italic',
                    }}
                  >
                    {partner.linkText}
                  </a>
                </MKTypography>
              </MKBox>
            </Grid>
          </Grid>
        ))}
      </Container>
    </MKBox>
  );
};

export default PartnerContent;
