// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore, collection, getDocs } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import * as XLSX from 'xlsx';

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBXElZvwgNQ8A2d3F4AZQ8xEkp6g3ZzAfI",
  authDomain: "savinganalysis.firebaseapp.com",
  databaseURL: "https://savinganalysis-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "savinganalysis",
  storageBucket: "savinganalysis.appspot.com",
  messagingSenderId: "260157699479",
  appId: "1:260157699479:web:9f08e468949469698240d2",
  measurementId: "G-K8597W7KTT"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const db = getFirestore(app);

const fetchDataFromCollection = async (collectionName) => {
  const data = [];
  try {
    const querySnapshot = await getDocs(collection(db, collectionName));
    querySnapshot.forEach((doc) => {
      data.push({ id: doc.id, ...doc.data(), collectionName });
    });
    return data;
  } catch (error) {
    console.error(`Error fetching data from collection ${collectionName}:`, error);
    return [];
  }
};

export const fetchCombinedDataFromFirebase = async (collectionNames) => {
  const combinedData = [];
  for (const collectionName of collectionNames) {
    const data = await fetchDataFromCollection(collectionName);
    combinedData.push(...data);
  }
  return combinedData;
};

export const saveDataToExcel = (tableData) => {
  const wb = XLSX.utils.book_new();

  Object.entries(tableData).forEach(([collectionName, data]) => {
    // Flatten the data if necessary
    let flattenedData = Array.isArray(data) ? data : [data];

    // Extract dynamic columns from the data
    const columns = flattenedData.length > 0 ? Object.keys(flattenedData[0]) : [];

    const worksheetData = flattenedData.map(item => {
      const row = {};
      columns.forEach(column => {
        row[column] = item[column] || '';
      });
      return row;
    });

    const ws = XLSX.utils.json_to_sheet(worksheetData);
    XLSX.utils.book_append_sheet(wb, ws, collectionName);
  });

  try {
    XLSX.writeFile(wb, 'data.xlsx'); // Directly save to file
    console.log('Excel file has been generated successfully.');
  } catch (error) {
    console.error('Error saving Excel file:', error);
  }
};